/* ==========================
   ❌ DELETE NOTIFICATION MODAL ❌
   ========================== */

/* Container principal pentru notificare */
.deletenotificationContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Fundal semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    backdrop-filter: blur(5px);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Notificarea propriu-zisă */
  .deletenotification {
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(15px);
    text-align: center;
    width: 320px;
    animation: slideIn 0.3s ease-in-out;
  }
  
  /* Mesajul notificării */
  .deletenotification p {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  /* Gruparea butoanelor */
  .deletebuttonGroup {
    display: flex;
    justify-content: space-around;
  }
  
  /* Butonul de confirmare (Yes) */
  .deleteconfirmButton {
    background: linear-gradient(135deg, #ff4e50, #ff6b6b);
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
  }
  
  .deleteconfirmButton:hover {
    background: linear-gradient(135deg, #ff6b6b, #ff4e50);
    transform: translateY(-3px);
  }
  
  /* Butonul de anulare (No) */
  .deletecancelButton {
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
  }
  
  .deletecancelButton:hover {
    background: linear-gradient(135deg, #0072ff, #00c6ff);
    transform: translateY(-3px);
  }
  
  /* Animație fadeIn */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Animație slideIn */
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  