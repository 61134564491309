/* ==========================
   📊 CHART MENU STYLING 📊
   ========================== */

/* Containerul principal al meniului */
.chartMenu {
    position: relative;
    display: inline-block;
  }
  
  /* Butonul pentru meniul dropdown */
  .chartMenumenuButton {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 5px;
    color: #ffffff;
    transition: color 0.3s ease, transform 0.2s ease;
  }
  
  .chartMenumenuButton:hover {
    color: #00c6ff;
    transform: scale(1.2);
  }
  
  /* Dropdown-ul meniului */
  .chartMenumenuDropdown {
    position: absolute;
    top: 40px;
    right: 0;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 10px;
    padding: 15px;
    min-width: 200px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    animation: fadeIn 0.3s ease-in-out;
    z-index: 1000;
  }
  
  /* Eticheta meniului dropdown */
  .chartMenumenuLabel {
    display: block;
    font-size: 1rem;
    color: #ffffff;
    margin-bottom: 8px;
    font-weight: 600;
  }
  
  /* Select dropdown pentru schimbarea tipului de chart */
  .chartMenumenuSelect {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: none;
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    font-size: 1rem;
    outline: none;
    transition: background 0.3s ease;
  }
  
  .chartMenumenuSelect:focus {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
  
  /* Butonul de descărcare a chart-ului în PDF */
  .chartMenudownloadButton {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
  }
  
  .chartMenudownloadButton:hover {
    background: linear-gradient(135deg, #0072ff, #00c6ff);
    transform: translateY(-3px);
  }
  
  /* Efect de fade-in pentru dropdown */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  