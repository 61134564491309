/* ==========================
   🌟 FIXED CONTACT PAGE 🌟
   ========================== */

/* General Page Styling */
.contactPageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  overflow: hidden;
  position: relative;
  align-items: center;
}

/* Video Background */
.contactPageVideoBackground {
  position: fixed; /* 🔥 FIX: Video-ul rămâne pe fundal */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(0.5) blur(9px);
  opacity: 1;
}

/* Header Menu */
.contactPageHeaderMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  position: fixed; /* 🔥 FIX: Meniul rămâne sus */
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}

/* 🔥 Spacer pentru a evita suprapunerea headerului peste conținut */
.contactPageSpacer {
  height: 80px;
  width: 100%;
}

/* Logo */
.contactPagelogo {
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.contactPagelogo:hover {
  transform: scale(1.1);
}

/* Navigation Menu */
.contactPageHeaderMenu nav ul {
  display: flex;
  list-style: none;
  gap: 25px;
}

.contactPageHeaderMenu nav ul li {
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.contactPageHeaderMenu nav ul li:hover {
  color: #00c6ff;
  transform: scale(1.1);
}

/* Main Content */
.contactPageContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - 160px); /* 🔥 FIX: Formularul nu mai e împins */
  padding: 20px;
  box-sizing: border-box;
}

/* Form Container */
.contactFormContainer {
  background: rgba(0, 0, 0, 0.6);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(15px);
  text-align: center;
  color: #ffffff;
  max-width: 600px;
  width: 100%;
  animation: fadeIn 0.8s ease-in-out;
}

.contactFormContainer h1 {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.contactPageForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contactPageInput,
.contactPageTextarea {
  width: 100%;
  padding: 14px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: #ffffff;
  font-size: 1rem;
  outline: none;
  transition: background 0.3s ease, border 0.3s ease;
}

.contactPageTextarea {
  height: 120px;
  resize: none;
}

/* Submit Button */
.contactPageButton {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 25px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.contactPageButton:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: translateY(-5px);
}

/* Footer */
.contactPageFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.contactPagefooterLink {
  margin: 0 15px;
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.contactPagefooterLink:hover {
  text-decoration: underline;
  color: #00c6ff;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}
