/* ==========================
   🌟 WOW! PREMIUM CONFIRMATION PAGE 🌟
   ========================== */

/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

/* General Page Styling */
.confirmationPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  text-align: center;
  padding-top: 100px; /* 🔥 Asigură că nu se suprapune cu header-ul */
}

/* Video Background */
.confirmationPageVideoBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  filter: brightness(0.5) blur(9px);
  opacity: 1;
}

/* Header */
.confirmationPageheaderMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}

/* Logo */
.confirmationPagelogo {
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.confirmationPagelogo:hover {
  transform: scale(1.1);
}

/* Navigation Menu */
.confirmationPageheaderMenu nav ul {
  display: flex;
  list-style: none;
  gap: 25px;
}

.confirmationPageheaderMenu nav ul li {
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.confirmationPageheaderMenu nav ul li:hover {
  color: #00c6ff;
  transform: scale(1.1);
}

/* Title */
.confirmationPageContainer h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}

/* Subtitle */
.confirmationPageContainer p {
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 30px;
  opacity: 0.9;
}

/* Button */
.confirmationPageButton {
  padding: 16px 32px;
  border-radius: 30px;
  border: none;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  outline: none;
}

.confirmationPageButton:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: translateY(-5px);
}

.confirmationPageButton:active {
  transform: translateY(2px);
}

/* Footer */
.confirmationPageFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.confirmationPagefooterLink {
  margin: 0 15px;
  cursor: pointer;
  font-size: 1rem;
}

.confirmationPagefooterLink:hover {
  text-decoration: underline;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}
