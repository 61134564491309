/* ==========================
   🚛 LOADS NOTIFICATIONS 🚛
   ========================== */

   .loadnotificationContainer {
    position: fixed;
    bottom: 20px;
    right: 20px;
    max-width: 420px;
    background: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3000;
    animation: fadeIn 0.5s ease-in-out;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }
  
  .loadnotification {
    flex-grow: 1;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.4;
    padding-right: 15px;
  }
  
  .loadnotificationcloseButton {
    background: transparent;
    border: none;
    color: #ff4e50;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease-in-out, transform 0.2s ease;
  }
  
  .loadnotificationcloseButton:hover {
    color: #ff6b6b;
    transform: scale(1.2);
  }
  
  .loadnotificationContainer:hover {
    transform: scale(1.05);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateX(30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .loadnotificationContainer.hide {
    opacity: 0;
    transform: translateX(30px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  