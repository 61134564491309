/* ==========================
   🚛 ULTIMATE PAGE STYLES 🚛
   ========================== */

/* Container principal */
.ultimatePage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  overflow: hidden;
  position: relative;
  background: #121212;
}

.chatNotification {
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  padding: 4px 8px;
  margin-left: 5px;
}



.headerultimatePage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  z-index: 1100; /* 🔥 Am ridicat z-index ca să fie vizibil */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* ✅ LOGO - Design Modern */
.logoultimatePage {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: transform 0.3s ease;
}

.logoPart1ultimatePage {
  color: #ffffff;
}

.logoPart2ultimatePage {
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.logoultimatePage:hover {
  transform: scale(1.08);
}

/* ✅ NAVIGATION MENU */
.navultimatePage {
  display: flex;
}

.mainMenuultimatePage {
  list-style: none;
  display: flex;
  gap: 30px;
}

.mainMenuultimatePage li {
  position: relative;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 500;
  transition: color 0.3s ease, transform 0.3s ease;
}

.mainMenuultimatePage li:hover {
  color: #00c6ff;
  transform: scale(1.1);
}

/* ✅ SUBMENIU - Drop-down Style */
.subMenuultimatePage {
  display: none;
  position: absolute;
  top: 30px;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  z-index: 500;
  list-style: none;
  width: 200px;
}

.mainMenuultimatePage li:hover .subMenuultimatePage {
  display: block;
}

.subMenuultimatePage li {
  padding: 10px;
  font-size: 1rem;
  text-align: left;
  transition: background 0.3s ease;
}

.subMenuultimatePage li:hover {
  background: rgba(0, 198, 255, 0.2);
}

/* ✅ BUTON "ADD NEW LOAD" */
.buttonultimatePage {
  padding: 10px 20px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  border: none;
  border-radius: 25px;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.buttonultimatePage:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: translateY(-3px);
}

/* ✅ LOGOUT BUTTON */
.buttonultimatePage.logout {
  background: linear-gradient(135deg, #ff4e50, #ff6b6b);
}

.buttonultimatePage.logout:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff4e50);
}

/* ✅ RESPONSIVE DESIGN */
@media (max-width: 1024px) {
  .headerultimatePage {
    padding: 10px 20px;
  }

  .mainMenuultimatePage {
    gap: 20px;
  }

  .logoultimatePage {
    font-size: 1.5rem;
  }

  .buttonultimatePage {
    font-size: 0.9rem;
    padding: 8px 15px;
  }
}

@media (max-width: 768px) {
  .headerultimatePage {
    flex-direction: column;
    height: auto;
    padding: 15px;
  }

  .navultimatePage {
    margin-top: 10px;
  }

  .mainMenuultimatePage {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .subMenuultimatePage {
    position: relative;
    width: 100%;
    text-align: center;
  }
}
/* ================================
   🚛 ULTIMATE DASHBOARD STYLES 🚛
   ================================ */

   .ultimatedashboardoverview {
    display: flex;
    width: 100%;
    height: 100vh;
    background: #121212;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    position: relative;
    overflow: hidden;
    padding-top: 70px; /* ✅ Fix ca să nu fie ascuns sub header */
  }
/* 🔹 Sidebar - Menținere pe lateral */
.sidebarultimatedashboardoverview {
  width: 250px;
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-right: 2px solid rgba(255, 255, 255, 0.1);
  position: fixed;
  top: 70px; /* ✅ Fix ca să nu fie sub header */
  left: 0;
  height: calc(100vh - 70px);
  z-index: 1050;
}


.sidebarultimatedashboardoverview ul {
  list-style: none;
  padding: 0;
}

.sidebarultimatedashboardoverview li {
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 5px;
}

.sidebarultimatedashboardoverview li:hover {
  background: rgba(0, 198, 255, 0.2);
  color: #00c6ff;
}

.mainContentultimatedashboardoverview {
  flex: 1;
  padding: 20px 40px;
  overflow-y: auto;
  margin-left: 250px;
  position: static; /* ✅ Elimină suprapunerea */
}

.mainContentultimatedashboardoverview:has(.chartsultimatedashboardoverview) .dashboardTitleContainer {
  opacity: 0; /* 🔥 Titlul dispare când utilizatorul derulează */
}

.mainContentultimatedashboardoverview h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
  position: sticky;
  top: 10px; /* ✅ Se vede corect, nu e ascuns sub header */
  background: #121212;
  z-index: 200;
  padding: 10px 0;
}

.dashboardTitleContainer {
  position: absolute;
  top: 0;
  left: 250px;
  width: calc(100% - 250px);
  background: #121212;
  z-index: 1000; /* ✅ Era 1100, acum mai mic */
  padding: 15px 20px;
  transition: opacity 0.3s ease-in-out;
}

.dashboardTitleultimatedashboardoverview {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  text-align: left;
}

.statsdashboardoverview {
  display: flex;
  flex-wrap: nowrap; /* ✅ Asigură că toate cardurile rămân pe o linie */
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
}
/* 🔹 Search Bar */
.searchultimatedashboardoverview {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 1rem;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.searchultimatedashboardoverview::placeholder {
  color: rgba(255, 255, 255, 0.6);
}


.statCardultimatedashboardoverview,
.statultimatedashboardoverview {
  background: linear-gradient(135deg, #00c6ff, #0072ff) !important; /* ✅ Readaugă gradientul */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.statCardultimatedashboardoverview h4,
.statultimatedashboardoverview h4 {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: white;
}

.statCardultimatedashboardoverview p,
.statultimatedashboardoverview p {
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
}

/* 🔹 Secțiunea Calendar + To-Do List */
.calendarSectionultimatedashboardoverview {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* 🔹 Calendar Container */
.calendarContainerultimatedashboardoverview {
  flex: 1;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

/* 🔹 Header Calendar */
.calendarHeaderultimatedashboardoverview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendarHeaderultimatedashboardoverview h2 {
  font-size: 1.5rem;
}

/* 🔹 Zilele săptămânii */
.weekDaysultimatedashboardoverview {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-weight: bold;
}

/* 🔹 Zile Calendar */
.calendarDaysultimatedashboardoverview {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.day {
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.day:hover {
  background: rgba(0, 198, 255, 0.2);
}

/* 🔹 Tasks pentru zile */
.hasTasksultimatedashboardoverview {
  background: rgba(0, 198, 255, 0.2);
}

/* 🔹 To-Do List */
.toDoListultimatedashboardoverview {
  flex: 1;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.toDoListultimatedashboardoverview h3 {
  font-size: 1.3rem;
}

.toDoListultimatedashboardoverview input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  border: none;
  outline: none;
}

.toDoListultimatedashboardoverview button {
  margin-top: 10px;
  padding: 10px;
  background: #00c6ff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.toDoListultimatedashboardoverview button:hover {
  background: #0072ff;
}

/* 🔹 Select Time Range */
.timeFilterultimatedashboardoverview {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.timeFilterultimatedashboardoverview select {
  padding: 10px;
  border-radius: 8px;
  outline: none;
}

.chartsultimatedashboardoverview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr)); /* Fixează lățimea minimă */
  gap: 20px;
  margin-top: 30px;
}


.chartContainerultimatedashboardoverview {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  min-height: 350px; /* Asigură o înălțime constantă */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chartHeaderultimatedashboardoverview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px;
  width: 100%;
}

.chartTitleCenterultimatedashboardoverview {
  flex: 1;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0;
}

.chartMenuRightultimatedashboardoverview {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.chartHeaderultimatedashboardoverview h4 {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  flex: 1;
  margin: 0;
}

.chartHeaderultimatedashboardoverview .chart-menu {
  position: absolute;
  top: 10px;
  right: 10px;
}

.chartContentultimatedashboardoverview {
  width: 100%;
  height: 280px; /* Fixează dimensiunea pentru toate chart-urile */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 🔹 Progress Goals */
/* 🎯 Stil pentru secțiunea de obiective */
.progressSectionultimatedashboardoverview {
  background: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin-top: 30px;
}

/* 🎯 Titlu - Stil modern */
.progressSectionultimatedashboardoverview h4 {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #00c6ff;
}

/* 🎯 Formular pentru adăugarea unui nou obiectiv */
.addGoalultimatedashboardoverview {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
}

/* 🎯 Label-urile */
.addGoalultimatedashboardoverview label {
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
}

/* 🎯 Select Dropdown */
.addGoalultimatedashboardoverview select,
.addGoalultimatedashboardoverview input {
  padding: 12px;
  border-radius: 8px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

/* 🎯 Efect Hover & Focus */
.addGoalultimatedashboardoverview select:hover,
.addGoalultimatedashboardoverview input:hover,
.addGoalultimatedashboardoverview select:focus,
.addGoalultimatedashboardoverview input:focus {
  background: rgba(255, 255, 255, 0.2);
  outline: none;
}

/* 🎯 Buton Adăugare */
.addGoalultimatedashboardoverview button {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

/* 🎯 Hover pe buton */
.addGoalultimatedashboardoverview button:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🎯 Selectare Obiectiv */
.goalSelectorultimatedashboardoverview {
  margin-top: 20px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
}

/* 🎯 Inputurile de dată */
.goalSelectorultimatedashboardoverview label {
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
  margin-right: 10px;
}

.progressContainerultimatedashboardoverview {
  margin-top: 25px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  text-align: center;
}


/* 🎯 Titlu progres */
.progressContainerultimatedashboardoverview h4 {
  font-size: 1.3rem;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-bottom: 15px;
}

.progressBarContainer {
  width: 100%;
  height: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.progressBar {
  height: 100%;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  transition: width 0.5s ease-in-out;
}

/* 🔹 Text Progres */
.progressText {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  margin-top: 5px;
}

/* 🎯 Input Update Progress */
.progressContainerultimatedashboardoverview input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  text-align: center;
}

/* 🎯 Hover & Focus pe input */
.progressContainerultimatedashboardoverview input:hover,
.progressContainerultimatedashboardoverview input:focus {
  background: rgba(255, 255, 255, 0.2);
  outline: none;
}

/* 🔹 Google Map */
#map {
  width: 100%;
  height: 400px;
  border-radius: 10px;
}

/* ✅ Responsive */
@media (max-width: 1024px) {
  .statsdashboardoverview {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .calendarSectionultimatedashboardoverview {
    flex-direction: column;
  }

  .chartsultimatedashboardoverview {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .ultimatedashboardoverview {
    flex-direction: column;
  }



  .sidebarultimatedashboardoverview {
    width: 100%;
    text-align: center;
  }

  .mainContentultimatedashboardoverview h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 0;
    text-align: left;
  }
}

.dispatcherTableContainer {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* 🔹 Titlul secțiunii */
.dispatcherTableContainer h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #00c6ff;
  text-align: center;
  margin-bottom: 15px;
}

/* 🔹 Stilizare tabel Dispatchers */
.dispatcherTable {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
}

/* 🔹 Header tabel */
.dispatcherTable thead {
  background: rgba(0, 198, 255, 0.3);
}

.dispatcherTable thead th {
  padding: 12px;
  font-size: 1rem;
  font-weight: bold;
  color: #00c6ff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

/* 🔹 Stilizare rânduri */
.dispatcherTable tbody tr {
  transition: background 0.3s ease-in-out;
  cursor: pointer;
}

.dispatcherTable tbody tr:hover {
  background: rgba(0, 198, 255, 0.2);
}

.dispatcherTable tbody td {
  padding: 10px;
  font-size: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.closeButtonDispatcher
{ position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.closeButtonDispatcher:hover {
  color: #ff3333;
}


.modalContentDispatcher
{
  background: #1e1e1e;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  position: relative;
}

.modalOverlayDispatcher {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modalContentDispatcher h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #00c6ff;
  margin-bottom: 10px;
}

.modalContentDispatcher h4 {
  font-size: 1.2rem;
  color: #ffffff;
  margin-top: 15px;
}

/* 🔹 Listele de șoferi și camioane */
.modalContentDispatcher ul {
  list-style: none;
  padding: 0;
}

.modalContentDispatcher li {
  padding: 8px;
  font-size: 1rem;
  transition: background 0.3s ease;
  cursor: pointer;
}

.modalContentDispatcher li:hover {
  background: rgba(0, 198, 255, 0.2);
  color: #00c6ff;
}

/* 🔹 Stilizare pentru Active Load */
.activeLoad {
  margin-top: 15px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  text-align: left;
}

/* 🔹 Titlu Active Load */
.activeLoad h4 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #00c6ff;
}

/* 🔹 Informații Active Load */
.activeLoad p {
  font-size: 1rem;
  margin: 5px 0;
}

/* 🔹 Stilizare pentru "No active load found." */
.modalContentDispatcher p {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

/* 🔹 Container pentru tabelul de inactivitate */
.driverInactiveContainer {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* 🔹 Titlul secțiunii */
.driverInactiveContainer h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #00c6ff;
  margin-bottom: 15px;
}

/* 🔹 Stilizare tabel Driver Inactivity */
.driverTableDispatcher {
  width: 100%;
  border-collapse: collapse;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
}

/* 🔹 Header tabel */
.driverTableDispatcher thead {
  background: rgba(0, 198, 255, 0.3);
}

.driverTableDispatcher thead th {
  padding: 12px;
  font-size: 1rem;
  font-weight: bold;
  color: #00c6ff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

/* 🔹 Rânduri tabel */
.driverTableDispatcher tbody tr {
  transition: background 0.3s ease-in-out;
}

.driverTableDispatcher tbody tr:hover {
  background: rgba(0, 198, 255, 0.2);
}

.driverTableDispatcher tbody td {
  padding: 10px;
  font-size: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}

/* 🔹 Responsive */
@media (max-width: 768px) {
  .dispatcherTableContainer,
  .driverInactiveContainer {
    padding: 15px;
  }

  .modalContentDispatcher {
    width: 90%;
  }

  .dispatcherTable thead th,
  .driverTableDispatcher thead th {
    font-size: 0.9rem;
  }

  .dispatcherTable tbody td,
  .driverTableDispatcher tbody td {
    font-size: 0.9rem;
  }
}

/* 🔹 Layout principal */
.driverdashboard {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  position: relative;
}

/* 🔹 Sidebar stilizat */
.sidebardriverdashboard {
  width: 250px;
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-right: 2px solid rgba(255, 255, 255, 0.1);
  position: fixed;
  top: 70px; /* ✅ Sidebar începe sub header */
  left: 0;
  height: calc(100vh - 70px); /* ✅ Ajustat să nu se suprapună */
  z-index: 1050; /* ✅ Peste alte elemente */
  overflow-y: auto;
}

.sidebardriverdashboard ul {
  list-style: none;
  padding: 0;
}

.sidebardriverdashboard li {
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 5px;
}

.sidebardriverdashboard li:hover {
  background: rgba(0, 198, 255, 0.2);
  color: #00c6ff;
}

/* 🔹 Conținut principal */
.mainContentdriverdashboard {
  flex: 1;
  padding: 40px;
  margin-left: 250px;
  margin-top: 70px; /* ✅ Conținutul începe sub header */
  overflow-y: auto;
  position: relative;
  z-index: 1000; /* ✅ Se afișează corect */
}

/* 🔹 Titlul Dashboard */
.titledriverdashboard {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

/* 🔹 Bară de căutare */
.searchBardriverdashboard {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 1rem;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.searchBardriverdashboard::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

/* 🔹 Tabel Șoferi */
.tabledriverdashboard {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.tabledriverdashboard th,
.tabledriverdashboard td {
  padding: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-align: left;
}

.tabledriverdashboard th {
  background: rgba(255, 255, 255, 0.1);
  font-weight: bold;
}

.tabledriverdashboard tr:hover {
  background: rgba(255, 255, 255, 0.05);
}

/* 🔹 Buton de vizualizare */
.tabledriverdashboard button {
  background: #0072ff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.tabledriverdashboard button:hover {
  background: #0057cc;
}

/* 🔹 Buton Back */
.backButtondriverdashboard {
  background: #ff9800;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
}

.backButtondriverdashboard:hover {
  background: #e68900;
}

/* 🔹 Detalii șofer */
.detailsSectiondriverdashboard {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.detailsSectiondriverdashboard h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.detailsSectiondriverdashboard p {
  font-size: 1rem;
  margin: 5px 0;
}

/* 🔹 Selectare interval de timp */
.chartControlsdriverdashboard {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.chartControlsdriverdashboard select {
  padding: 10px;
  border-radius: 8px;
  outline: none;
}

/* 🔹 Containere grafice */
.chartContainerdriverdashboard {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
}

.chartHeaderdriverdashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.chartHeaderdriverdashboard h4 {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  flex: 1;
  margin: 0;
}

.chartContentdriverdashboard {
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ✅ Responsive */
@media (max-width: 1024px) {
  .sidebardriverdashboard {
    width: 200px;
  }

  .mainContentdriverdashboard {
    margin-left: 200px;
    padding: 20px;
  }

  .searchBardriverdashboard {
    width: 100%;
  }

  .tabledriverdashboard th,
  .tabledriverdashboard td {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .driverdashboard {
    flex-direction: column;
  }

  .sidebardriverdashboard {
    width: 100%;
    text-align: center;
    position: relative;
    height: auto;
  }

  .mainContentdriverdashboard {
    margin-left: 0;
  }

  .tabledriverdashboard {
    font-size: 0.9rem;
  }

  .chartContainerdriverdashboard {
    width: 100%;
    min-height: auto;
  }
}

/* 🔹 Layout principal */
.truckdashboard {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  position: relative;
}

/* 🔹 Sidebar stilizat */
.sidebartruckdashboard {
  width: 250px;
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-right: 2px solid rgba(255, 255, 255, 0.1);
  position: fixed;
  top: 70px; /* ✅ Sidebar începe sub header */
  left: 0;
  height: calc(100vh - 70px); /* ✅ Ajustat să nu se suprapună */
  z-index: 1050; /* ✅ Peste alte elemente */
  overflow-y: auto;
}

.sidebartruckdashboard ul {
  list-style: none;
  padding: 0;
}

.sidebartruckdashboard li {
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 5px;
}

.sidebartruckdashboard li:hover {
  background: rgba(0, 198, 255, 0.2);
  color: #00c6ff;
}

/* 🔹 Conținut principal */
.mainContenttruckdashboard {
  flex: 1;
  padding: 40px;
  margin-left: 250px;
  margin-top: 70px; /* ✅ Conținutul începe sub header */
  overflow-y: auto;
  position: relative;
  z-index: 1000; /* ✅ Se afișează corect */
}

/* 🔹 Titlul Dashboard */
.titletruckdashboard {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

/* 🔹 Tabel Camioane */
.tabletruckdashboard {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.tabletruckdashboard th,
.tabletruckdashboard td {
  padding: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-align: left;
}

.tabletruckdashboard th {
  background: rgba(255, 255, 255, 0.1);
  font-weight: bold;
}

.tabletruckdashboard tr:hover {
  background: rgba(255, 255, 255, 0.05);
}

/* 🔹 Buton de vizualizare */
.tabletruckdashboard button {
  background: #0072ff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.tabletruckdashboard button:hover {
  background: #0057cc;
}

/* 🔹 Buton Back */
.backButtontruckdashboard {
  background: #ff9800;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
}

.backButtontruckdashboard:hover {
  background: #e68900;
}

/* 🔹 Detalii camion */
.detailsSectiontruckdashboard {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.detailsSectiontruckdashboard h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.detailsSectiontruckdashboard p {
  font-size: 1rem;
  margin: 5px 0;
}

/* 🔹 Selectare interval de timp */
.chartControlstruckdashboard {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.chartControlstruckdashboard select {
  padding: 10px;
  border-radius: 8px;
  outline: none;
}

/* 🔹 Containere grafice */
.chartContainertruckdashboard {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
}

.chartHeadertruckdashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.chartHeadertruckdashboard h4 {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  flex: 1;
  margin: 0;
}

.chartContenttruckdashboard {
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ✅ Responsive */
@media (max-width: 1024px) {
  .sidebartruckdashboard {
    width: 200px;
  }

  .mainContenttruckdashboard {
    margin-left: 200px;
    padding: 20px;
  }

  .tabletruckdashboard th,
  .tabletruckdashboard td {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .truckdashboard {
    flex-direction: column;
  }

  .sidebartruckdashboard {
    width: 100%;
    text-align: center;
    position: relative;
    height: auto;
  }

  .mainContenttruckdashboard {
    margin-left: 0;
  }

  .tabletruckdashboard {
    font-size: 0.9rem;
  }

  .chartContainertruckdashboard {
    width: 100%;
    min-height: auto;
  }
}

/* ======================
   🚛 Load Dashboard Styles 🚛
   ====================== */

   .loaddashboard {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 20px;
    background-color: #121212;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    width: 100vw;
    min-height: 100vh;
    position: relative;
  }
  
  /* Sidebar Styling */
  .sidebarLoadDashboard {
    width: 250px;
    background: rgba(0, 0, 0, 0.9);
    padding: 20px;
    border-right: 2px solid rgba(255, 255, 255, 0.1);
    position: fixed;
    top: 70px;
    left: 0;
    height: calc(100vh - 70px);
    z-index: 1050;
    overflow-y: auto;
  }
  
  .sidebarLoadDashboard ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebarLoadDashboard li {
    padding: 15px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 5px;
  }
  
  .sidebarLoadDashboard li:hover {
    background: rgba(0, 198, 255, 0.2);
    color: #00c6ff;
  }
  
  .filtersloaddashboardContainer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    position: fixed; /* ✅ Rămâne fix dedesubt de header */
    top: 70px; /* ✅ Se aliniază exact sub header */
    left: 250px; /* ✅ Se aliniază cu sidebar */
    width: calc(100vw - 250px); /* ✅ Se adaptează la ecran */
    padding: 10px;
    background: rgba(0, 0, 0, 0.95);
    border-radius: 5px;
    z-index: 1050; /* ✅ Mai mare decât tabelul */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  }
  
/* 🔹 Filtre și Selectoare de Dată */
.filtersloaddashboard,
.datePickersloaddashboard {
  display: flex;
  gap: 10px;
  align-items: center;
}
  
  
  .tableContainerloaddashboard {
    margin-top: 120px; /* ✅ Lasă loc sub filtre */
    overflow-x: auto;
    margin-left: 250px;
    width: calc(100vw - 250px);
  }
  .tableloaddashboard {
    width: 100%;
    border-collapse: collapse;
    background: rgb(0, 0, 0);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: rgb(255, 255, 255);
  }
  
  .tableloaddashboard th {
    background-color: #00c6ff;
    color: white;
    padding: 12px;
    text-align: left;
    font-weight: bold;
  }
  
  .tableloaddashboard td {
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1); /* ✅ Linii mai subtile */
    text-align: left;
  }
  .tableloaddashboard th {
    background-color: #3498db;
    color: white;
  }
  
  .tableloaddashboard tr:hover {
    background-color: #000000;
    cursor: pointer;
  }
  
  /* Pagination */
  .paginationloaddashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: 250px;
  }
  
  .paginationloaddashboard button {
    padding: 5px 10px;
    background: #3498db;
    color: white;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 5px;
  }
  
  .paginationloaddashboard button:disabled {
    background: #bdc3c7;
    cursor: not-allowed;
  }
  
  .loadDetailsloaddashboard {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    background: #1a1a1a;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    z-index: 2000;
  }
  
  .closeButtonloaddashboard {
    background: #ff4e50;
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .closeButtonloaddashboard:hover {
    background: #e60023;
  }

  
  .chartContainerLoadDashboard {
    padding: 20px;
    background: rgb(0, 0, 0);
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap; /* ✅ Permite alinierea corectă */
    justify-content: space-between;
    gap: 20px;
    margin-left: 250px;
    width: calc(100vw - 250px);
    max-height: 600px;
    overflow-y: auto;
  }
  
  /* ✅ Stil individual pentru fiecare chart */
  .statusDistributionChart,
  .driverPerformanceChart{
    flex: 1; /* ✅ Ajustare dinamică */
    min-width: 350px; /* ✅ Lățime minimă pentru aspect responsiv */
    max-width: 450px; /* ✅ Asigură dimensiuni egale */
    height: 250px; /* ✅ Dimensiune uniformă */
    background: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .rateComparisonChart {
    width: 100%;
    max-width: 450px; /* ✅ Dimensiune fixă pentru layout stabil */
    height: 250px; /* ✅ FORȚĂM ÎNĂLȚIMEA FIXĂ */
    max-height: 250px; /* ✅ NU PERMITEM CREȘTEREA INFINITĂ */
    overflow: hidden; /* ✅ Elimină scroll-ul */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    flex: none; /* ✅ IMPORTANT: NU permite flex-grow */
    position: relative; /* ✅ Stabilitate pe poziționare */
  }
  
.chartContainerLoadDashboard h3 {
  color: black;
  font-size: 1.2rem; /* ✅ Redus pentru aspect compact */
}
/* Stilizare globală pentru selector */
/* ✅ Stil principal pentru Selector */
.customLoadDashboardSelect {
  width: 200px !important;
}

/* ✅ Fundal negru pentru selector */
.customLoadDashboardSelect__control {
  background-color: black !important;
  color: white !important;
  border: 1px solid #555 !important;
}

/* ✅ Text alb în interiorul selectorului */
.customLoadDashboardSelect__single-value {
  color: white !important;
}

/* ✅ Placeholder alb-gri */
.customLoadDashboardSelect__placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
}

/* ✅ Iconițele (dropdown arrow & clear button) */
.customLoadDashboardSelect__indicator {
  color: white !important;
}

/* ✅ Dropdown-ul */
.customLoadDashboardSelect__menu {
  background-color: black !important;
}

/* ✅ Fiecare opțiune din dropdown */
.customLoadDashboardSelect__option {
  background-color: black !important;
  color: white !important;
  cursor: pointer;
}

/* ✅ Opțiunea selectată */
.customLoadDashboardSelect__option--is-selected {
  background-color: #333 !important;
  color: white !important;
}

/* ✅ Hover pe opțiuni */
.customLoadDashboardSelect__option:hover {
  background-color: #444 !important;
}

/* ✅ Bordura la hover */
.customLoadDashboardSelect__control:hover {
  border-color: #00c6ff !important;
}


@media (max-width: 1024px) {
  .chartContainerLoadDashboard {
    margin-left: 200px;
    width: calc(100vw - 200px);
  }
  .statusDistributionChart,
  .driverPerformanceChart,
  .rateComparisonChart {
    min-width: 300px;
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .ohartContainerLoadDashboard {
    margin-left: 0;
    width: 100%;
  }
  .statusDistributionChart,
  .driverPerformanceChart,
  .rateComparisonChart {
    min-width: 100%;
    max-width: 100%;
  }
}

  @media (max-width: 1024px) {
    .sidebarLoadDashboard {
      width: 200px;
    }
  
    .filtersloaddashboard, .datePickersloaddashboard {
      margin-left: 200px;
    }
  
    .tableContainerloaddashboard, .paginationloaddashboard, .chartContainerLoadDashboard {
      margin-left: 200px;
      width: calc(100vw - 200px);
    }
  }
  
  @media (max-width: 768px) {
    .sidebarLoadDashboard {
      width: 100%;
      text-align: center;
      position: relative;
      height: auto;
    }
  
    .filtersloaddashboard, .datePickersloaddashboard {
      margin-left: 0;
    }
  
    .tableContainerloaddashboard, .paginationloaddashboard, .chartContainerLoadDashboard {
      margin-left: 0;
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    .filtersloaddashboardContainer {
      left: 0;
      width: 100%;
    }
    .tableContainerloaddashboard {
      margin-left: 0;
      width: 100%;
    }
  }

  /* ==============================
   🚛 ULTIMATE ADD LOAD STYLES 🚛
   ============================== */

/* 🔹 Container Principal */
.ultimateaddloadsectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* 🔥 Aliniere corectă a conținutului */
  width: 100vw;
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 50px 20px;
  padding-top: 120px; /* 🔥 Crește distanța față de header */
}

/* 🔹 Stil pentru Titlul "Add New Load" */
.titleultimateaddload {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #00c6ff; /* 🔹 Albastru pentru evidențiere */
  margin-bottom: 20px; /* 🔥 Adaugă spațiu sub titlu */
}


/* 🔹 Formular */
.loadFormultimateaddload {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  background: rgba(0, 0, 0, 0.8);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
}

.formRowultimateaddload {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 15px;
}

/* 🔹 Grup de input */
.formGroupultimateaddload,
.formGroupultimateEquipmentaddload,
.formGroupultimateDriveraddload,
.formGroupultimateTeamDriversaddload,
.formGroupultimateTruckaddload,
.formGroupultimateTraileraddload,
.formGroupultimateStatusaddload,
.formGroupultimatePaymentStatusaddload {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* 🔹 Input-uri și Select */
.inputFieldultimateaddload,
.selectFieldultimateaddload,
.textareaFieldultimateaddload,
.selectFieldEquipmentultimateaddload,
.selectFieldultimateStatusaddload,
.selectFieldultimatePaymentStatusaddload {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}


.inputFieldultimateaddload::placeholder,
.textareaFieldultimateaddload::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

/* 🔹 Select Dropdown - Global */
.selectFieldultimateaddload,
.selectFieldEquipmentultimateaddload,
.selectFieldultimateStatusaddload,
.selectFieldultimatePaymentStatusaddload {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 1rem;
}

.selectFieldultimateaddload option,
.selectFieldEquipmentultimateaddload option,
.selectFieldultimateStatusaddload option,
.selectFieldultimatePaymentStatusaddload option {
  background: #121212; /* 🔥 Fundal negru */
  color: #ffffff; /* 🔥 Text alb */
}
.selectFieldultimateaddload:hover,
.selectFieldultimateaddload:focus,
.selectFieldEquipmentultimateaddload:hover,
.selectFieldEquipmentultimateaddload:focus,
.selectFieldultimateStatusaddload:hover,
.selectFieldultimateStatusaddload:focus,
.selectFieldultimatePaymentStatusaddload:hover,
.selectFieldultimatePaymentStatusaddload:focus {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  outline: none;
}

.selectFieldultimateaddload::-webkit-select-placeholder,
.selectFieldEquipmentultimateaddload::-webkit-select-placeholder,
.selectFieldultimateStatusaddload::-webkit-select-placeholder,
.selectFieldultimatePaymentStatusaddload::-webkit-select-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.selectFieldultimateaddload::-ms-expand,
.selectFieldEquipmentultimateaddload::-ms-expand,
.selectFieldultimateStatusaddload::-ms-expand,
.selectFieldultimatePaymentStatusaddload::-ms-expand {
  display: none;
}

/* 🔹 Select Special Cases */
.selectFieldEquipmentultimateaddload {
  background: rgba(0, 150, 255, 0.1);
}

.selectFieldultimateStatusaddload {
  background: rgba(0, 200, 0, 0.1);
}

.selectFieldultimatePaymentStatusaddload {
  background: rgba(200, 0, 0, 0.1);
}

/* 🔹 Textarea */
.textareaFieldultimateaddload {
  min-height: 100px;
  resize: none;
}

/* ==============================
   🚛 BUTTON STYLES 🚛
   ============================== */

/* 🔹 Buton Principal */
.submitButtonultimateaddload {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
}

.submitButtonultimateaddload:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🔹 Buton Cancel */
.closeBtnultimateaddload {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #ff4e50, #ff6b6b);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}

.closeBtnultimateaddload:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff4e50);
  transform: scale(1.05);
}

/* 🔹 Butoane pentru Shippers și Consignees */
.addButtonShipperultimateaddload,
.addButtonConsigneeultimateaddload {
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
}

.addButtonShipperultimateaddload:hover,
.addButtonConsigneeultimateaddload:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

.removeButtonShipperultimateaddload,
.removeButtonConsigneeultimateaddload {
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(135deg, #ff4e50, #ff6b6b);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.removeButtonShipperultimateaddload:hover,
.removeButtonConsigneeultimateaddload:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff4e50);
  transform: scale(1.05);
}

/* 🔹 Secțiunea Shippers și Consignees */
.sectionultimateaddload {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.sectionultimateaddload h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-bottom: 15px;
}

/* 🔹 Upload Section */
.uploadSectionultimateaddload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
}

.fileInputultimateaddload {
  width: 100%;
  max-width: 300px;
  margin-top: 10px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(0, 198, 255, 0.5);
  border-radius: 8px;
  font-size: 1rem;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.fileInputultimateaddload:hover {
  background: rgba(255, 255, 255, 0.2);
  border-color: #00c6ff;
}

/* ==============================
   📱 RESPONSIVE DESIGN
   ============================== */

@media (max-width: 1024px) {
  .formRowultimateaddload {
    flex-direction: column;
  }

  .formGroupultimateaddload {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .ultimateaddloadsectionContainer {
    padding: 30px 10px;
  }

  .loadFormultimateaddload {
    width: 100%;
  }

  .sectionultimateaddload {
    padding: 15px;
  }

  .uploadSectionultimateaddload {
    padding: 10px;
  }

  .submitButtonultimateaddload,
  .closeBtnultimateaddload {
    font-size: 1rem;
    padding: 10px;
  }
}
/* 🔹 Responsive */
@media (max-width: 1024px) {
  .formRowultimateaddload {
    flex-direction: column;
  }

  .formGroupultimateaddload {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .formRowultimateaddload {
    gap: 10px;
  }

  .inputFieldultimateaddload,
  .selectFieldultimateaddload,
  .textareaFieldultimateaddload {
    padding: 10px;
  }
}

.ultimatedriverPaySection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 100px 40px 50px;
  box-sizing: border-box;
}

.titledriverPayultimatedriverPay {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #00c6ff;
  margin-bottom: 20px;
}

.driverDetailsultimatedriverPay {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  background: rgba(0, 0, 0, 0.8);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  margin-top: 20px;
}

/* 🔹 Grupuri de Input */
.formGroupultimatedriverPay,
.formGroupdriverPayultimatedriverPay {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

/* 🔹 Input și Select */
.inputFieldultimatedriverPay,
.selectFieldultimatedriverPay,
.selectFielddriverPayultimate {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

/* 🔹 Placeholder */
.inputFieldultimatedriverPay::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

/* 🔹 Hover și Focus */
.inputFieldultimatedriverPay:hover,
.inputFieldultimatedriverPay:focus,
.selectFieldultimatedriverPay:hover,
.selectFieldultimatedriverPay:focus,
.selectFielddriverPayultimate:hover,
.selectFielddriverPayultimate:focus {
  background: rgba(255, 255, 255, 0.2);
  outline: none;
}

/* 🔹 Container mai jos să nu se suprapună */
.ultimatedriverPaySection {
  padding-top: 120px; /* ✅ Crește distanța față de meniu */
}

/* 🔹 Rând de input-uri */
.formRowultimatedriverPay {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
  flex-wrap: wrap; /* ✅ Asigură responsive */
}
.selectorGroupPayroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 250px; /* ✅ Aliniere uniformă */
}

.selectorGroupPayroll label {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 5px;
}


.selectFielddriverPayultimate,
.selectFieldultimatedriverPay {
  width: 100%;
  padding: 12px;
  height: 50px; /* ✅ Fixăm înălțimea pentru uniformizare */
  border-radius: 8px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  appearance: none; /* ✅ Elimină stilul default al browserului */
}

.selectFielddriverPayultimate:hover,
.selectFieldultimatedriverPay:hover,
.selectFielddriverPayultimate:focus,
.selectFieldultimatedriverPay:focus {
  background: rgba(255, 255, 255, 0.2);
  outline: none;
}

/* 🔹 Opțiuni Dropdown (fundal negru și text alb) */
.selectFielddriverPayultimate option,
.selectFieldultimatedriverPay option {
  background: #000000;
  color: #ffffff;
  padding: 10px;
}

/* 🔹 Hover pentru Dropdown */
.selectFielddriverPayultimate option:hover,
.selectFieldultimatedriverPay option:hover {
  background: #0072ff;
  color: #ffffff;
}

/* 🔹 Butoane */
.actionButtonultimatedriverPay {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
}

.actionButtonultimatedriverPay:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🔹 Buton Adăugare */
.addButtonultimatedriverPay {
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
}

.addButtonultimatedriverPay:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🔹 Buton Ștergere */
.removeButtonultimatedriverPay {
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(135deg, #ff4e50, #ff6b6b);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.removeButtonultimatedriverPay:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff4e50);
  transform: scale(1.05);
}

/* 🔹 Logo Upload */
.logoPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.logoImageultimatedriverPay {
  max-width: 100px;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
}

.deleteButtonultimatedriverPay {
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(135deg, #ff4e50, #ff6b6b);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.deleteButtonultimatedriverPay:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff4e50);
  transform: scale(1.05);
}

.loadDetailsultimatedriverPay {
  width: 100%;
  max-width: 100%; /* ✅ Se extinde pe întreaga lățime */
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #000000;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
}
/* 🔹 Listă Încărcături */
.loadListultimatedriverPay {
  list-style: none;
  padding: 0;
}

.loadItemultimatedriverPay {
  display: flex;
  justify-content: space-between; /* ✅ Aliniere corectă pe orizontală */
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 15px 20px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease-in-out;
  width: 100%; /* ✅ Se întinde pe toată lățimea paginii */
  box-sizing: border-box;
}

/* 🔹 Elemente din fiecare încărcătură */
.loadItemultimatedriverPay div {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap; /* ✅ Evită înghesuirea dacă nu încape */
}

/* 🔹 Load # și Detalii */
.loadItemultimatedriverPay strong {
  color: #ffffff;
  font-size: 1rem;
}

/* 🔹 Checkbox-urile - Mai bine spațiate */
.loadItemultimatedriverPay input {
  margin-right: 15px;
  transform: scale(1.2);
}

/* 🔹 Spațierea textului în detalii */
.loadItemultimatedriverPay span {
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.8);
}


/* 🔹 Secțiunea Adiții/Deduceri */
.recurringSectionultimatedriverPay {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.recurringItemultimatedriverPay {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

/* 🔹 Buton Salvare */
.saveButtonultimatedriverPay {
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}

.saveButtonultimatedriverPay:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🔹 Buton Ștergere */
.deleteButtonultimatedriverPay {
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #ff4e50, #ff6b6b);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}

.deleteButtonultimatedriverPay:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff4e50);
  transform: scale(1.05);
}


.inputFieldultimatedriverPay {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 1rem;
}

.inputFieldultimatedriverPay::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

/* 🔹 Responsive */
@media (max-width: 1024px) {
  .formRowultimatedriverPay {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .ultimatedriverPaySection {
    padding: 30px 10px;
  }

  .driverDetailsultimatedriverPay {
    width: 100%;
  }

  .actionButtonultimatedriverPay,
  .removeButtonultimatedriverPay {
    font-size: 1rem;
    padding: 10px;
  }
}

/* 🔹 Responsivitate */
@media (max-width: 768px) {
  .selectFielddriverPayultimate,
  .selectFieldultimatedriverPay {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .loadItemultimatedriverPay {
    flex-direction: column;
    padding: 20px;
  }

  .loadItemultimatedriverPay div {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* 🔹 Responsivitate */
@media (max-width: 768px) {
  .formRowultimatedriverPay {
    flex-direction: column;
  }

  .selectorGroupPayroll {
    max-width: 100%;
  }

  .selectFielddriverPayultimate,
  .selectFieldultimatedriverPay {
    font-size: 0.9rem;
  }
}

.settingsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 100px 40px 50px;
  box-sizing: border-box;
}

/* 🔹 Titlul Principal */
.titlesettings {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #00c6ff;
  margin-bottom: 20px;
}

/* 🔹 Form Settings */
.settingsFormsettings {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  background: rgba(0, 0, 0, 0.8);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
}

/* 🔹 Input Fields */
.inputFieldsettings {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

/* 🔹 Placeholder */
.inputFieldsettings::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

/* 🔹 Hover și Focus */
.inputFieldsettings:hover,
.inputFieldsettings:focus {
  background: rgba(255, 255, 255, 0.2);
  outline: none;
}
/* 🔹 Buton de salvare */
.submitButtonClientsettings {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
}

.submitButtonClientsettings:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🔹 Subtitlu Drivers */
.subtitleDriversettings {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 30px;
  color: #00c6ff;
  text-align: center;
}

.selectDriverFieldsettings {
  width: 250px; /* ✅ Mai mare */
  padding: 10px;
  border-radius: 8px;
  border: none;
  background: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

/* 🔹 Selector Account Management (mai mic) */
.selectFieldAccountsettings {
  width: 250px; /* ✅ Mai mare */
  padding: 10px;
  border-radius: 8px;
  border: none;
  background: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  text-align: center;
}


.selectDriverFieldsettings:hover,
.selectDriverFieldsettings:focus,
.selectFieldAccountsettings:hover,
.selectFieldAccountsettings:focus {
  background: rgba(0, 0, 0, 1);
  outline: none;
}

.selectDriverFieldsettings option,
.selectFieldAccountsettings option {
  background: #000000;
  color: #ffffff;
  padding: 10px;
}

.selectDriverFieldsettings option:hover,
.selectFieldAccountsettings option:hover {
  background: #0072ff;
  color: #ffffff;
}
/* 🔹 Driver Form */
.driverFormsettings {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

/* 🔹 Input Driver */
.inputFieldDriversettings {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

/* 🔹 Buton Actualizare Driver */
.submitButtonDriversettings {
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
}

.submitButtonDriversettings:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🔹 Buton Blochează Driver */
.deleteButtonDriversettings {
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(135deg, #ff4e50, #ff6b6b);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
}

.deleteButtonDriversettings:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff4e50);
  transform: scale(1.05);
}

/* 🔹 Account Management */
.subtitleAccountultimateAccount {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 30px;
  color: #00c6ff;
  text-align: center;
}

.selectFieldAccountsettings {
  width: 180px; /* ✅ Mai mic */
  padding: 8px;
  border-radius: 8px;
  border: none;
  background: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  font-size: 0.95rem;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

/* 🔹 Form Account */
.accountFormsettings {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

/* 🔹 Buton Edit - Compact și Modern */
.editButtonAccountnsettings {
  padding: 6px 12px; /* ✅ Mai mic */
  border-radius: 5px;
  border: none;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 0.85rem; /* ✅ Font redus */
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 90px; /* ✅ Mai scurt */
  text-align: center;
}

/* 🔹 Hover Effect */
.editButtonAccountnsettings:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🔹 Butoane Account */
.submitButtonAccountsettings {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}

.submitButtonAccountsettings:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🔹 Buton Cancel */
.cancelButtonAccountsettings {
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #ff4e50, #ff6b6b);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}

.cancelButtonAccountsettings:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff4e50);
  transform: scale(1.05);
}

.actionButtonfaqsettings {
  width: 200px; /* ✅ Mai scurt */
  padding: 10px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
}

.actionButtonfaqsettings:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}
.editButtonAccountsettings,
.deleteButtonAccountsettings {
  padding: 8px 10px;
  border-radius: 5px;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}


/* 🔹 Buton Edit */
.editButtonAccountsettings {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
}

.editButtonAccountsettings:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}
/* 🔹 Buton Delete */
.deleteButtonAccountsettings {
  background: linear-gradient(135deg, #ff4e50, #ff6b6b);
  color: white;
}

.deleteButtonAccountsettings:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff4e50);
  transform: scale(1.05);
}

/* 🔹 Buton Two-Factor Authentication */
.submitButtonTwoFactorsettings,
.deleteButtonTwoFactorsettings {
  width: 220px; /* ✅ Mai scurt */
  padding: 10px;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

/* 🔹 Enable 2FA */
.submitButtonTwoFactorsettings {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
}

.submitButtonTwoFactorsettings:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🔹 Disable 2FA */
.deleteButtonTwoFactorsettings {
  background: linear-gradient(135deg, #ff4e50, #ff6b6b);
  color: white;
}

.deleteButtonTwoFactorsettings:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff4e50);
  transform: scale(1.05);
}

/* 🔹 Responsive */
@media (max-width: 1024px) {
  .settingsFormsettings {
    max-width: 100%;
  }
  
  .selectDriverFieldsettings,
  .selectFieldAccountsettings,
  .actionButtonfaqsettings {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .settingsSection {
    padding: 30px 10px;
  }

  .submitButtonTwoFactorsettings,
  .deleteButtonTwoFactorsettings {
    width: 100%;
  }

  .editButtonAccountsettings,
  .deleteButtonAccountsettings {
    width: 100px;
  }
}

/* ============================
   🧾 ACCOUNTING MANAGER STYLES 🧾
   ============================ */

/* 🔹 Container Principal - Acoperă întreaga pagină */
.ultimateaccountingManager {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw; /* ✅ Ocupă întreaga lățime a ecranului */
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  padding: 100px 40px 50px;
  box-sizing: border-box;
}

/* 🔹 Titlu Principal */
.titleultimateaccountingManager {
  font-size: 2rem;
  font-weight: bold;
  color: #00c6ff;
  text-align: center;
  margin-bottom: 20px;
}

/* 🔹 Tab-uri pentru Open/Sent Invoices */
.tabContainerultimateaccountingManager {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
  width: 100%;
}

.tabContainerultimateaccountingManager button {
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  width: 200px; /* ✅ Butoane de dimensiune egală */
}

.activeTabultimateaccountingManager {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
}

.tabContainerultimateaccountingManager button:hover {
  background: rgba(0, 198, 255, 0.3);
}

/* 🔹 Factoring Details */
.factoringSectionultimateaccountingManager {
  width: 100%;
  max-width: 100%; /* ✅ Ocupă întreaga lățime */
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
}

.factoringSectionultimateaccountingManager h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #00c6ff;
  text-align: center;
  margin-bottom: 10px;
}

/* 🔹 Input & Textarea */
.inputFieldultimateaccountingManager,
.textareaFieldultimateaccountingManager {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 1rem;
  margin-bottom: 10px;
}

.inputFieldultimateaccountingManager::placeholder,
.textareaFieldultimateaccountingManager::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

/* 🔹 Invoice Table */
.invoiceTableultimateaccountingManager {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
}

.invoiceTableultimateaccountingManager th,
.invoiceTableultimateaccountingManager td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.invoiceTableultimateaccountingManager th {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-weight: bold;
}

.invoiceTableultimateaccountingManager tr:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* 🔹 Factoring Upload */
.uploadButtonultimateaccountingManager {
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.uploadButtonultimateaccountingManager:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🔹 Buton Send Factoring */
.sendFactoringButtonultimateaccountingManager {
  padding: 12px 20px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.sendFactoringButtonultimateaccountingManager:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

.downloadExcelButtonultimateaccountingManager{
  padding: 12px 20px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.downloadExcelButtonultimateaccountingManager:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* ✅ Asigură că pagina se întinde complet */
.fullWidthContainerultimateaccountingManager {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* ✅ Responsive */
@media (max-width: 1024px) {
  .factoringSectionultimateaccountingManager {
    max-width: 100%;
  }

  .invoiceTableultimateaccountingManager {
    overflow-x: auto;
    display: block;
  }
}

@media (max-width: 768px) {
  .tabContainerultimateaccountingManager {
    flex-direction: column;
    align-items: center;
  }

  .invoiceTableultimateaccountingManager th,
  .invoiceTableultimateaccountingManager td {
    padding: 8px;
    font-size: 0.9rem;
  }
}

/* ==============================================
   🚛 DRIVER PAY MANAGER STYLES 🚛
   ============================================== */

   .ultimatemanagerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    min-height: 100vh;
    background: #121212;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    padding: 80px 40px 50px;
    box-sizing: border-box;
  }
  
  /* 🔹 Titlul Principal */
  .titleDriverPayManager {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #00c6ff;
    margin-bottom: 20px;
  }
  /* 🔹 Filtru */
  .filterSectionultimatemanager {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .filterSectionultimatemanager button {
    padding: 10px 15px;
    border-radius: 8px;
    border: none;
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    color: white;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .filterSectionultimatemanager button:hover {
    background: linear-gradient(135deg, #0072ff, #00c6ff);
    transform: scale(1.05);
  }
  
  .activeultimatemanager {
    background: linear-gradient(135deg, #ff9800, #ffb84d) !important;
    transform: scale(1.1);
  }
  
  /* 🔹 Căutare */
  .searchBarultimatemanager {
    width: 100%;
    max-width: 500px;
    padding: 12px;
    border-radius: 8px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
    margin-bottom: 20px;
  }
  
  .searchBarultimatemanager::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  
  .searchBarultimatemanager:hover,
  .searchBarultimatemanager:focus {
    background: rgba(255, 255, 255, 0.2);
    outline: none;
  }
  
  /* 🔹 Listă entități */
  .listContainerultimatemanager {
    width: 100%;
    max-width: 600px;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  }
  
  .entityListultimatemanager {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .entityListultimatemanager li {
    padding: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .entityListultimatemanager li:hover {
    background: rgba(0, 198, 255, 0.2);
    color: #00c6ff;
  }
  
  .selectedEntityultimatemanager {
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    color: white !important;
    font-weight: bold;
    transform: scale(1.05);
  }
  
  /* 🔹 Payroll Files Section */
  .filesSectionultimatemanager {
    width: 100%;
    max-width: 700px;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  }
  
  .filesSectionultimatemanager h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #00c6ff;
    text-align: center;
    margin-bottom: 15px;
  }
  
  /* 🔹 Listă fișiere */
  .fileListultimatemanager {
    list-style: none;
    padding: 0;
  }
  
  .fileListultimatemanager li {
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .fileListultimatemanager li span {
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
  }
  
  .fileListultimatemanager p {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
  }
  
  /* 🔹 Buton Download */
  .downloadButtonultimateDriverPayManager {
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    color: white;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
  }
  
  .downloadButtonultimateDriverPayManager:hover {
    background: linear-gradient(135deg, #0072ff, #00c6ff);
    transform: scale(1.05);
  }
  
  .downloadButtonultimateDriverPayManager:active {
    transform: scale(0.95);
  }
  
  /* 🔹 Responsive */
  @media (max-width: 1024px) {
    .ultimatemanagerContainer {
      padding: 50px 20px;
    }
  
    .listContainerultimatemanager,
    .filesSectionultimatemanager {
      max-width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .filterSectionultimatemanager {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  
    .searchBarultimatemanager {
      width: 100%;
      max-width: 100%;
    }
  
    .fileListultimatemanager li {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .downloadButtonultimateDriverPayManager {
      width: 100%;
    }
  }
  
  /* ==============================================
   ⛽ FUEL REPORT STYLES ⛽
   ============================================== */

.fuelReportContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 80px 40px 50px;
  box-sizing: border-box;
}

/* 🔹 Titlul Principal */
.titlefuelReport {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #00c6ff;
  margin-bottom: 20px;
}

/* 🔹 Form Container */
.formContainerfuelReport {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  background: rgba(0, 0, 0, 0.8);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.formContainerfuelReport label {
  font-weight: bold;
  margin-top: 10px;
}

.formContainerfuelReport input,
.formContainerfuelReport select {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 1rem;
  margin-top: 5px;
  transition: all 0.3s ease-in-out;
}

.formContainerfuelReport input::placeholder,
.formContainerfuelReport select {
  color: rgba(255, 255, 255, 0.6);
}

.formContainerfuelReport input:hover,
.formContainerfuelReport input:focus,
.formContainerfuelReport select:hover,
.formContainerfuelReport select:focus {
  background: rgba(255, 255, 255, 0.2);
  outline: none;
}

/* 🔹 Buton Submit */
.submitButtonfuelReport {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
}

.submitButtonfuelReport:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🔹 Buton Cancel */
.cancelButtonfuelReport {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #ff4e50, #ff6b6b);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}

.cancelButtonfuelReport:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff4e50);
  transform: scale(1.05);
}

/* 🔹 Stil general pentru toate Selectoarele */
.customSelectFuelReport {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.9); /* 🔥 Fundal negru */
  color: white; /* 🔥 Text alb */
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

/* 🔹 Hover și Focus */
.customSelectFuelReport:hover,
.customSelectFuelReport:focus {
  background: rgba(0, 0, 0, 1);
  outline: none;
  border: 1px solid rgba(0, 198, 255, 0.5);
}

/* 🔹 Opțiuni din dropdown */
.customSelectFuelReport option {
  background: #000000; /* 🔥 Fundal negru */
  color: white; /* 🔥 Text alb */
  padding: 10px;
}

/* 🔹 Hover pe opțiuni */
.customSelectFuelReport option:hover {
  background: rgba(0, 198, 255, 0.2);
  color: #00c6ff;
}

/* 🔹 Grup Butoane */
.buttonGroupfuelReport {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
}

/* 🔹 Buton Export */
.exportButtonfuelReport,
.selectAllButtonfuelReport {
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.exportButtonfuelReport:hover,
.selectAllButtonfuelReport:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🔹 Container Tabel */
.fuelReportTable {
  width: 100%;
  max-width: 1200px;
  border-collapse: collapse;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
}

/* 🔹 Header Tabel */
.tableHeaderfuelReport {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-weight: bold;
  text-align: left;
}

.tableHeaderfuelReport th {
  padding: 12px;
  text-align: left;
  font-size: 1rem;
}

/* 🔹 Rânduri Tabel */
.tableRowfuelReport {
  transition: background 0.3s ease-in-out;
}

.tableRowfuelReport:nth-child(even) {
  background: rgba(255, 255, 255, 0.05);
}

.tableRowfuelReport:hover {
  background: rgba(0, 198, 255, 0.2);
}

/* 🔹 Celule */
.tableBodyfuelReport td {
  padding: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* 🔹 Buton Edit */
.editButtonfuelReport {
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.editButtonfuelReport:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🔹 Buton Delete */
.deleteButtonfuelReport {
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(135deg, #ff4e50, #ff6b6b);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.deleteButtonfuelReport:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff4e50);
  transform: scale(1.05);
}

/* 🔹 Container Paginare */
.paginationContainerfuelReport {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

/* 🔹 Buton Previous */
.prevButtonfuelReport {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.prevButtonfuelReport:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

/* 🔹 Buton Next */
.nextButtonfuelReport {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.nextButtonfuelReport:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

.nextButtonfuelReport:disabled {
  background: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
  transform: none;
}

/* ==========================
   🚛 INVOICE STYLES 🚛
   ========================== */

/* ✅ Container Principal */
.invoiceContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 50px 20px;
  padding-top: 120px; /* 🔥 Crește distanța față de header */
}

/* ✅ Titlu */
.invoiceContainer h2 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #00c6ff;
  margin-bottom: 20px;
}

/* ✅ Tab Buttons */
.invoiceTabs {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.invoiceTabs button {
  padding: 10px 20px;
  border-radius: 25px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.invoiceTabs button:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: translateY(-3px);
}

/* ✅ Search Bar */
.searchInputinvoice {
  width: 100%;
  max-width: 600px;
  padding: 12px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 1rem;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
}

.searchInputinvoice::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

/* ✅ Notes Input */
.notesInputinvoice {
  width: 100%;
  max-width: 600px;
  height: 80px;
  padding: 10px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 1rem;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
}

/* ✅ Table Styling */
.invoiceTableinvoice {
  width: 100%;
  max-width: 1200px;
  border-collapse: collapse;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  margin-bottom: 20px;
}

.invoiceTableinvoice th,
.invoiceTableinvoice td {
  padding: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
}

.invoiceTableinvoice th {
  background: rgba(255, 255, 255, 0.1);
  font-weight: bold;
}

.invoiceTableinvoice tr:hover {
  background: rgba(255, 255, 255, 0.05);
}

/* ✅ Buttons */
.generateInvoiceButton, 
.previewPDFButton, 
.saveInvoiceButton, 
.closePreviewButton, 
.sendEmailButton, 
.regenerateInvoiceButton {
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
}

.generateInvoiceButton {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
}

.previewPDFButton {
  background: linear-gradient(135deg, #28a745, #218838);
  color: white;
}

.saveInvoiceButton {
  background: linear-gradient(135deg, #ffc107, #e0a800);
  color: black;
}

.closePreviewButton {
  background: linear-gradient(135deg, #dc3545, #c82333);
  color: white;
}

.sendEmailButton {
  background: linear-gradient(135deg, #17a2b8, #138496);
  color: white;
}

.regenerateInvoiceButton {
  background: linear-gradient(135deg, #6f42c1, #5936a3);
  color: white;
}

/* ✅ Hover Effects */
.generateInvoiceButton:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
}

.previewPDFButton:hover {
  background: linear-gradient(135deg, #218838, #28a745);
}

.saveInvoiceButton:hover {
  background: linear-gradient(135deg, #e0a800, #ffc107);
}

.closePreviewButton:hover {
  background: linear-gradient(135deg, #c82333, #dc3545);
}

.sendEmailButton:hover {
  background: linear-gradient(135deg, #138496, #17a2b8);
}

.regenerateInvoiceButton:hover {
  background: linear-gradient(135deg, #5936a3, #6f42c1);
}

/* ✅ PDF Preview */
.pdfOverlayinvoice {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.pdfViewerContainerinvoice {
  background: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 900px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* ✅ Edit Invoice Number */
.invoiceEditContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.invoiceEditContainer label {
  font-size: 14px;
  font-weight: bold;
}

.invoiceEditContainer input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

/* ✅ Actions */
.pdfActionsInvoiceContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.buttonGroupinvoice {
  display: flex;
  gap: 10px;
}

/* ✅ Email Fields */
.emailFieldsinvoice {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.emailFieldsinvoice input, 
.emailFieldsinvoice textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 14px;
}

/* ✅ Responsive */
@media (max-width: 1024px) {
  .invoiceContainer {
    padding-top: 100px;
  }

  .invoiceTabs {
    flex-direction: column;
  }

  .searchInputinvoice, 
  .notesInputinvoice {
    max-width: 90%;
  }

  .invoiceTableinvoice {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .invoiceContainer {
    padding-top: 80px;
  }

  .invoiceTabs {
    flex-direction: column;
  }

  .invoiceTableinvoice {
    font-size: 0.8rem;
  }

  .pdfViewerContainerinvoice {
    width: 90%;
  }
}
.invoiceTabs {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.tabButtonInvoice {
  padding: 12px 20px;
  border-radius: 25px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.tabButtonInvoice:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: translateY(-3px);
}

.tabButtonInvoice:active {
  transform: scale(0.95);
}

/* ================================
   🚛 ULTIMATE LOAD MANAGER STYLES 🚛
   ================================ */

/* 🔹 Container Principal */
.ultimateloadManagerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 50px 20px;
  padding-top: 120px; /* 🔥 Ajustat să nu fie ascuns de header */
}

/* 🔹 Titlu */
.titleultimateloadManager {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #00c6ff; /* 🔹 Evidențiere titlu */
  margin-bottom: 20px;
}

/* 🔹 Căutare și Filtrare */
.searchInputultimateloadManager,
.selectFieldultimateloadManager {
  width: 90%;
  max-width: 500px;
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 1rem;
  background: rgba(0, 0, 0, 0.9); /* 🔥 Fundal negru */ 
   color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.searchInputultimateloadManager::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.searchInputultimateloadManager:hover,
.searchInputultimateloadManager:focus,
.selectFieldultimateloadManager:hover,
.selectFieldultimateloadManager:focus {
  background: rgba(0, 0, 0, 1); /* 🔥 Fundal full negru la focus */
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

/* 🔹 Tabel Load Manager */
.ultimateloadManagerloadTable {
  width: 95%;
  max-width: 1200px;
  border-collapse: collapse;
  margin-top: 20px;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.ultimateloadManagerloadTable th,
.ultimateloadManagerloadTable td {
  padding: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-align: left;
}

.ultimateloadManagerloadTable th {
  background: rgba(255, 255, 255, 0.1);
  font-weight: bold;
}

.ultimateloadManagerloadRow:hover {
  background: rgba(0, 198, 255, 0.2);
  cursor: pointer;
}

.editLoadFormultimateloadManager {
  width: 90%;
  max-width: 500px; /* 🔥 Lățime redusă pentru compactare */
  max-height: 80vh; /* 🔥 Limită max-height pentru a nu ocupa tot ecranul */
  overflow-y: auto; /* 🔥 Scroll activ când depășește înălțimea */
  background: rgba(0, 0, 0, 0.9);
  padding: 20px; /* 🔥 Mai puțin padding pentru economie de spațiu */
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
}

.editLoadFormultimateloadManager h3 {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 20px;
}

/* 🔹 Inputuri și Select-uri */
.inputFieldultimateloadManager,
.textareaFieldultimateloadManager
 {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 1rem;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.inputFieldultimateloadManager:hover,
.textareaFieldultimateloadManager:hover,
.inputFieldultimateloadManager:focus,
.textareaFieldultimateloadManager:focus {
  background: rgba(255, 255, 255, 0.2);
}

.selectFieldultimateloadManager option {
  background: #000000; /* 🔥 Fundal negru */
  color: white; /* 🔥 Text alb */
  padding: 10px;
}

.selectFieldultimateloadManager option:hover {
  background: rgba(0, 198, 255, 0.2);
  color: #00c6ff;
}

/* 🔹 Sub-form pentru Shippers și Consignees */
.subFormultimateloadManager {
  background: rgba(0, 0, 0, 0.6);
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
}

/* 🔹 Butoane */
.saveButtonultimateloadManager,
.deleteButtonultimateloadManager
 {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.closeButtonultimateloadManager {
  position: absolute; /* 🔥 Fixat în colț */
  top: 10px;
  right: 10px;
  width: 35px; /* 🔹 Dimensiune fixă */
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(135deg, #ff4e50, #ff6b6b);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.closeButtonultimateloadManager:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff4e50);
  transform: scale(1.1);
}

.saveButtonultimateloadManager {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
}

.saveButtonultimateloadManager:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: scale(1.05);
}

.deleteButtonultimateloadManager {
  background: linear-gradient(135deg, #ff4e50, #ff6b6b);
  color: white;
}

.deleteButtonultimateloadManager:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff4e50);
  transform: scale(1.05);
}

.closeButtonultimateloadManager {
  background: linear-gradient(135deg, #ff9800, #ffb74d);
  color: white;
}

.closeButtonultimateloadManager:hover {
  background: linear-gradient(135deg, #ffb74d, #ff9800);
  transform: scale(1.05);
}

/* 🔹 Responsivitate */
@media (max-width: 1024px) {
  .ultimateloadManagerSection {
    padding-top: 100px;
  }

  .editLoadFormultimateloadManager {
    width: 80%;
  }

  .ultimateloadManagerloadTable th,
  .ultimateloadManagerloadTable td {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .ultimateloadManagerSection {
    padding-top: 80px;
  }

  .editLoadFormultimateloadManager {
    width: 95%;
  }

  .ultimateloadManagerloadTable {
    font-size: 0.9rem;
  }

  .saveButtonultimateloadManager,
  .deleteButtonultimateloadManager,
  .closeButtonultimateloadManager {
    font-size: 0.9rem;
    padding: 10px;
  }
}

/* ==============================
   🚛 ULTIMATE LOAD FINDER STYLES 🚛
   ============================== */

/* 🔹 Containerul principal */
.loadFinderSection {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: left;
}

/* 🔹 Titlul secțiunii */
.loadFinderSection h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #00c6ff;
  text-align: center;
  margin-bottom: 15px;
}

/* 🔹 Căutare */
.searchInputloadFinder {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 1rem;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.searchInputloadFinder::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

/* 🔹 Lista de Load-uri */
.loadListloadFinder {
  list-style: none;
  padding: 0;
}

/* 🔹 Stilizare Load */
.loadItemloadFinder {
  background: rgba(0, 0, 0, 0.3);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  transition: background 0.3s ease-in-out;
  cursor: pointer;
  color: #ffffff;
}

.loadItemloadFinder:hover {
  background: rgba(0, 198, 255, 0.2);
}

/* 🔹 Load Details */
.loadDetailsloadFinder {
  background: #1e1e1e;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  position: relative;
  color: #ffffff;
  margin: auto;
}

.loadDetailsloadFinder h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #00c6ff;
  margin-bottom: 10px;
}

.loadDetailsloadFinder p {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

/* 🔹 Listele de expeditori și destinatari */
.loadDetailsloadFinder ul {
  list-style: none;
  padding: 0;
}

.loadDetailsloadFinder li {
  padding: 8px;
  font-size: 1rem;
  transition: background 0.3s ease;
  cursor: pointer;
}

.loadDetailsloadFinder li:hover {
  background: rgba(0, 198, 255, 0.2);
  color: #00c6ff;
}

/* 🔹 Buton Next Status */
.nextButtonloadFind{
  background: #0072ff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-top: 10px;
}

.nextButtonloadFind:hover {
  background: #0057cc;
}

/* 🔹 Buton Close */
.closeButtonloadFinder {
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.modalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: black;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  min-width: 400px;
  text-align: center;
}

.closeButtonloadFinder:hover {
  color: #ff3333;
}

/* 🔹 Responsive */
@media (max-width: 768px) {
  .loadFinderSection {
    padding: 15px;
  }

  .loadDetailsloadFinder {
    width: 90%;
  }

  .searchInputloadFinder {
    font-size: 0.9rem;
  }
}


/* ==============================
   🚛 ULTIMATE DRIVERS STYLES 🚛
   ============================== */

/* 🔹 Container Principal */
.ultimatedriversSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 50px 20px;
  padding-top: 120px; /* ✅ Asigură spațiu sub header */
}

/* 🔹 Titlu */
.titleultimatedrivers {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #00c6ff;
  margin-bottom: 20px;
}

/* 🔹 Form */
.driverFormultimatedrivers {
  width: 90%;
  max-width: 800px;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* 🔹 Input Fields */
.inputFieldultimatedrivers, .textareaFieldultimatedrivers {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.selectFieldultimatedrivers {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background: rgba(0, 0, 0, 0.9); /* 🔥 Fundal negru */
  color: white; /* 🔥 Text alb */
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.selectFieldultimatedrivers:hover,
.selectFieldultimatedrivers:focus {
  background: rgba(0, 0, 0, 1); /* 🔥 Fundal full negru la focus */
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.selectFieldultimatedrivers option {
  background: #000000; /* 🔥 Fundal negru */
  color: white; /* 🔥 Text alb */
  padding: 10px;
}

/* 🔹 Hover pe Opțiuni */
.selectFieldultimatedrivers option:hover {
  background: rgba(0, 198, 255, 0.2);
  color: #00c6ff;
}

.inputFieldultimatedrivers::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.inputFieldultimatedrivers:focus, .selectFieldultimatedrivers:focus, .textareaFieldultimatedrivers:focus {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

/* 🔹 Form Row */
.formRowultimatedrivers {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.formGroupultimatedrivers {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* 🔹 Search Input */
.searchInputultimatedrivers {
  width: 90%;
  max-width: 600px;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.searchInputultimatedrivers:focus {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

/* 🔹 Lista Șoferilor */
.driverListultimatedrivers {
  width: 90%;
  max-width: 1000px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 60vh; /* ✅ Scroll pentru liste lungi */
  overflow-y: auto;
}

/* 🔹 Stil pentru fiecare șofer */
.driverListItemultimatedrivers {
  background: rgba(0, 0, 0, 0.8);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.driverListItemultimatedrivers:hover {
  background: rgba(0, 198, 255, 0.2);
  transform: translateY(-3px);
  border-left: 5px solid #00c6ff;
}

/* 🔹 Butoane */
.editButtonultimatedrivers, .deleteButtonultimatedrivers, .notMoreWorkingButtonultimatedrivers, .submitButtonultimatedrivers {
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: bold;
}

.editButtonultimatedrivers {
  background: #007bff;
}

.deleteButtonultimatedrivers {
  background: #ff3b3b;
}

.notMoreWorkingButtonultimatedrivers {
  background: #ff9f00;
}

.submitButtonultimatedrivers {
  background: #00c6ff;
}

.editButtonultimatedrivers:hover, .deleteButtonultimatedrivers:hover, .notMoreWorkingButtonultimatedrivers:hover, .submitButtonultimatedrivers:hover {
  transform: scale(1.05);
}

/* 🔹 Mesaj pentru licență expirată */
.expiredMessageultimatedrivers {
  color: #ff3b3b;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

/* 🔹 Modal pentru Detalii Șofer */
.driverDetailsModalultimatedrivers {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  width: 80%;
  max-width: 500px;
  color: #ffffff;
  text-align: center;
  z-index: 1000;
}

.closeButtonultimatedrivers {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
}

/* ✅ Responsive */
@media (max-width: 768px) {
  .searchInputultimatedrivers, .driverFormultimatedrivers {
    max-width: 100%;
  }

  .driverListultimatedrivers {
    max-height: 50vh;
  }

  .formRowultimatedrivers {
    flex-direction: column;
  }
}
/* 🔹 Buton "View Details" */
.viewDetailsButtonultimatedrivers {
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: bold;
  background: #00c6ff; /* 🔹 Albastru neon */
  margin-left: 10px;
}

.viewDetailsButtonultimatedrivers:hover {
  transform: scale(1.05);
  background: #009ad8;
}

/* 🔹 Buton "View Not More Working Drivers" */
.viewNotWorkingButtonultimatedrivers {
  width: 90%;
  max-width: 600px;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: bold;
  background: #ff9f00; /* 🔹 Portocaliu energic */
  margin-top: 20px;
  display: block;
  text-align: center;
}

.viewNotWorkingButtonultimatedrivers:hover {
  transform: scale(1.05);
  background: #e58e00;
}

/* ==============================
   🚛 ULTIMATE TRUCKS STYLES 🚛
   ============================== */

/* 🔹 Container Principal */
.ultimatetrucksSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 50px 20px;
  padding-top: 120px; /* ✅ Spațiu sub header */
}

/* 🔹 Titlu */
.titleultimatetrucks {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #00c6ff;
  margin-bottom: 20px;
}

/* 🔹 Form */
.truckFormultimatetrucks {
  width: 90%;
  max-width: 800px;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* 🔹 Input Fields */
.inputFieldultimatetrucks, 
.textareaFieldultimatetrucks {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.selectFieldultimatetrucks{
width: 100%;
padding: 12px;
border-radius: 8px;
border: none;
background: rgba(0, 0, 0, 0.9); /* 🔥 Fundal negru */
color: white; /* 🔥 Text alb */
font-size: 1rem;
cursor: pointer;
transition: all 0.3s ease-in-out;
}

.selectFieldultimatetrucks:hover,
.selectFieldultimatetrucks:focus
{
  background: rgba(0, 0, 0, 1); /* 🔥 Fundal full negru la focus */
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.selectFieldultimatetrucks option 
{
  background: #000000; /* 🔥 Fundal negru */
  color: white; /* 🔥 Text alb */
  padding: 10px;
}

.selectFieldultimatetrucks option:hover
{
  background: rgba(0, 198, 255, 0.2);
  color: #00c6ff;
}

.inputFieldultimatetrucks::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.inputFieldultimatetrucks:focus, 
.selectFieldultimatetrucks:focus, 
.textareaFieldultimatetrucks:focus {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

/* 🔹 Form Row */
.formRowultimatetrucks {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.formGroupultimatetrucks {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* 🔹 Search Input */
.searchInputultimatetrucks {
  width: 90%;
  max-width: 600px;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.searchInputultimatetrucks:focus {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

/* 🔹 Lista Camioanelor */
.truckListultimatetrucks {
  width: 90%;
  max-width: 1000px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 60vh; /* ✅ Scroll pentru liste lungi */
  overflow-y: auto;
}

/* 🔹 Stil pentru fiecare camion */
.truckListItemultimatetrucks {
  background: rgba(0, 0, 0, 0.8);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.truckListItemultimatetrucks:hover {
  background: rgba(0, 198, 255, 0.2);
  transform: translateY(-3px);
  border-left: 5px solid #007bff;
}

/* 🔹 Butoane */
.editButtonultimatetrucks, 
.deleteButtonultimatetrucks, 
.submitButtonultimatetrucks {
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: bold;
}

.editButtonultimatetrucks {
  background: #007bff;
}

.deleteButtonultimatetrucks {
  background: #ff3b3b;
}

.submitButtonultimatetrucks {
  background: #007bff;
}

.editButtonultimatetrucks:hover, 
.deleteButtonultimatetrucks:hover, 
.submitButtonultimatetrucks:hover {
  transform: scale(1.05);
}

/* 🔹 Responsive */
@media (max-width: 768px) {
  .searchInputultimatetrucks, .truckFormultimatetrucks {
    max-width: 100%;
  }

  .truckListultimatetrucks {
    max-height: 50vh;
  }

  .formRowultimatetrucks {
    flex-direction: column;
  }
}

/* ==============================
   🚛 ULTIMATE TRAILERS STYLES 🚛
   ============================== */

/* 🔹 Container Principal */
.ultimatetrailersSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 50px 20px;
  padding-top: 120px; /* ✅ Spațiu sub header */
}

/* 🔹 Titlu */
.titleultimatetrailers {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #007bff;
  margin-bottom: 20px;
}

/* 🔹 Form */
.trailerFormultimatetrailers {
  width: 90%;
  max-width: 800px;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* 🔹 Input Fields */
.inputFieldultimatetrailers,  
.textareaFieldultimatetrailers {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.inputFieldultimatetrailers::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.selectFieldultimatetrailers
{
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background: rgba(0, 0, 0, 0.9); /* 🔥 Fundal negru */
  color: white; /* 🔥 Text alb */
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.selectFieldultimatetrailers:hover,
.selectFieldultimatetrailers:focus
{
  background: rgba(0, 0, 0, 1); /* 🔥 Fundal full negru la focus */
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.selectFieldultimatetrailers option
{
  background: #000000; /* 🔥 Fundal negru */
  color: white; /* 🔥 Text alb */
  padding: 10px;
}

.selectFieldultimatetrailers option:hover
{
  background: rgba(0, 198, 255, 0.2);
  color: #00c6ff;
}

.inputFieldultimatetrailers:focus, 
.textareaFieldultimatetrailers:focus {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

/* 🔹 Form Row */
.formRowultimatetrailers {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.formGroupultimatetrailers {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* 🔹 Search Input */
.searchInputultimatetrailers {
  width: 90%;
  max-width: 600px;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.searchInputultimatetrailers:focus {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

/* 🔹 Lista Trailerelor */
.trailerListultimatetrailers {
  width: 90%;
  max-width: 1000px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 60vh; /* ✅ Scroll pentru liste lungi */
  overflow-y: auto;
}

/* 🔹 Stil pentru fiecare trailer */
.trailerListItemultimatetrailers {
  background: rgba(0, 0, 0, 0.8);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trailerListItemultimatetrailers:hover {
  background: rgba(0, 198, 255, 0.2);
  transform: translateY(-3px);
  border-left: 5px solid #007bff;
}

/* 🔹 Butoane */
.editButtonultimatetrailers, 
.deleteButtonultimatetrailers, 
.submitButtonultimatetrailers {
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: bold;
}

.editButtonultimatetrailers {
  background: #007bff;
}

.deleteButtonultimatetrailers {
  background: #ff3b3b;
}

.submitButtonultimatetrailers {
  background: #007bff;;
}

.editButtonultimatetrailers:hover, 
.deleteButtonultimatetrailers:hover, 
.submitButtonultimatetrailers:hover {
  transform: scale(1.05);
}

/* 🔹 Mesaje de alarmă pentru expirări */
.alarmMessageultimatetrailers {
  color: #ff3b3b;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

/* 🔹 Responsive */
@media (max-width: 768px) {
  .searchInputultimatetrailers, .trailerFormultimatetrailers {
    max-width: 100%;
  }

  .trailerListultimatetrailers {
    max-height: 50vh;
  }

  .formRowultimatetrailers {
    flex-direction: column;
  }
}

/* ==============================
   🚛 ULTIMATE BROKERS STYLES 🚛
   ============================== */

/* 🔹 Container Principal */
.ultimatebrokersSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 50px 20px;
  padding-top: 120px;
}

/* 🔹 Titlu */
.titleultimatebrokers {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #00c6ff;
  margin-bottom: 20px;
}

/* 🔹 Form */
.brokerFormultimatebrokers {
  width: 90%;
  max-width: 800px;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* 🔹 Input Fields */
.inputFieldultimatebrokers, 
.selectFieldultimatebrokers, 
.textareaFieldultimatebrokers {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.inputFieldultimatebrokers::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.inputFieldultimatebrokers:focus, 
.selectFieldultimatebrokers:focus, 
.textareaFieldultimatebrokers:focus {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

/* 🔹 Form Row */
.formRowultimatebrokers {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.formGroupultimatebrokers {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* 🔹 Search Input */
.searchInputultimatebrokers {
  width: 90%;
  max-width: 600px;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.searchInputultimatebrokers:focus {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

/* 🔹 Lista Brokerilor */
.brokerListultimatebrokers {
  width: 90%;
  max-width: 1000px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 60vh;
  overflow-y: auto;
}

/* 🔹 Stil pentru fiecare broker */
.brokerListItemultimatebrokers {
  background: rgba(0, 0, 0, 0.8);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brokerListItemultimatebrokers:hover {
  background: rgba(0, 198, 255, 0.2);
  transform: translateY(-3px);
  border-left: 5px solid #00c6ff;
}

/* 🔹 Butoane */
.editButtonultimatebrokers, 
.deleteButtonultimatebrokers, 
.submitButtonultimatebrokers {
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: bold;
}

.editButtonultimatebrokers {
  background: #007bff;
}

.deleteButtonultimatebrokers {
  background: #ff3b3b;
}

.submitButtonultimatebrokers {
  background: #00c6ff;
}

.editButtonultimatebrokers:hover, 
.deleteButtonultimatebrokers:hover, 
.submitButtonultimatebrokers:hover {
  transform: scale(1.05);
}

/* ✅ Responsive */
@media (max-width: 768px) {
  .searchInputultimatebrokers, .brokerFormultimatebrokers {
    max-width: 100%;
  }

  .brokerListultimatebrokers {
    max-height: 50vh;
  }

  .formRowultimatebrokers {
    flex-direction: column;
  }
}

/* ==============================
   🚛 ULTIMATE SHIPPERS STYLES 🚛
   ============================== */

/* 🔹 Container Principal */
.ultimateshippersSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 50px 20px;
  padding-top: 120px;
}

/* 🔹 Titlu */
.titleultimateshippers {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #00c6ff;
  margin-bottom: 20px;
}

/* 🔹 Form */
.shipperFormultimateshippers {
  width: 90%;
  max-width: 800px;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* 🔹 Input Fields */
.inputFieldultimateshippers, 
.selectFieldultimateshippers, 
.textareaFieldultimateshippers {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

/* 🔹 Lista Shippers */
.shipperListultimateshippers {
  width: 90%;
  max-width: 1000px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 60vh;
  overflow-y: auto;
}

.editButtonultimateshippers,
.deleteButtonultimateshippers,
.submitButtonultimateshippers {
padding: 10px 15px;
font-size: 1rem;
border-radius: 8px;
border: none;
cursor: pointer;
transition: all 0.3s ease-in-out;
color: #fff;
font-weight: bold;
}

.editButtonultimateshippers
{
  background: #007bff;
}

.deleteButtonultimateshippers
{
  background: #ff3b3b;
}

.submitButtonultimateshippers
{
  background: #00c6ff;
}

.editButtonultimateshippers:hover,
.deleteButtonultimateshippers:hover,
.submitButtonultimateshippers:hover { transform: scale(1.05);
}

/* 🔹 Stil pentru fiecare shipper */
.shipperListItemultimateshippers {
  background: rgba(0, 0, 0, 0.8);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shipperListItemultimateshippers:hover {
  background: rgba(0, 198, 255, 0.2);
  transform: translateY(-3px);
  border-left: 5px solid #00c6ff;
}

.searchInputultimateshippers {
  width: 90%;
  max-width: 600px;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;

}

.searchInputultimateshippers:focus {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

/* ✅ Responsive */
@media (max-width: 768px) {
  .searchInputultimateshippers, .shipperFormultimateshippers {
    max-width: 100%;
  }

  .shipperListultimateshippers {
    max-height: 50vh;
  }

  .formRowultimateshippers {
    flex-direction: column;
  }
}

/* ==============================
   🚛 ULTIMATE CONSIGNEES STYLES 🚛
   ============================== */

/* 🔹 Container Principal */
.ultimateconsigneesSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  min-height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 50px 20px;
  padding-top: 120px;
}

/* 🔹 Titlu */
.titleultimateconsignees {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #00c6ff;
  margin-bottom: 20px;
}

/* 🔹 Form */
.consigneeFormultimateconsignees {
  width: 90%;
  max-width: 800px;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* 🔹 Input Fields */
.inputFieldultimateconsignees, 
.selectFieldultimateconsignees, 
.textareaFieldultimateconsignees {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.inputFieldultimateconsignees::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.inputFieldultimateconsignees:focus, 
.selectFieldultimateconsignees:focus, 
.textareaFieldultimateconsignees:focus {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

/* 🔹 Form Row */
.formRowultimateconsignees {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.formGroupultimateconsignees {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* 🔹 Search Input */
.searchInputultimateconsignees {
  width: 90%;
  max-width: 600px;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.searchInputultimateconsignees:focus {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

/* 🔹 Lista Consignees */
.consigneeListultimateconsignees {
  width: 90%;
  max-width: 1000px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 60vh;
  overflow-y: auto;
}

/* 🔹 Stil pentru fiecare consignee */
.consigneeListItemultimateconsignees {
  background: rgba(0, 0, 0, 0.8);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.consigneeListItemultimateconsignees:hover {
  background: rgba(0, 198, 255, 0.2);
  transform: translateY(-3px);
  border-left: 5px solid #00c6ff;
}

/* 🔹 Butoane */
.editButtonultimateconsignees, 
.deleteButtonultimateconsignees, 
.submitButtonultimateconsignees {
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: bold;
}

.editButtonultimateconsignees {
  background: #007bff;
}

.deleteButtonultimateconsignees {
  background: #ff3b3b;
}

.submitButtonultimateconsignees {
  background: #00c6ff;
}

.editButtonultimateconsignees:hover, 
.deleteButtonultimateconsignees:hover, 
.submitButtonultimateconsignees:hover {
  transform: scale(1.05);
}

/* ✅ Responsive */
@media (max-width: 768px) {
  .searchInputultimateconsignees, .consigneeFormultimateconsignees {
    max-width: 100%;
  }

  .consigneeListultimateconsignees {
    max-height: 50vh;
  }

  .formRowultimateconsignees {
    flex-direction: column;
  }
}

/* ==============================
   🚛 ULTIMATE TRUCKING STYLES 🚛
   ============================== */

   .truckingSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    background: #121212;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    padding-top: 80px;
    gap: 0; /* ✅ Asigură că elementele nu au spațiu între ele */
  }
/* 🔹 Titlu */
.titletrucking {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #00c6ff;
  margin-bottom: 20px;
}

/* 🔹 Controale pentru șoferi */
.truckingsdriverControls {
  width: 90%;
  max-width: 800px;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

/* 🔹 Select Field */
.selectFieldtrucking {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(0, 0, 0, 0.9); /* 🔥 Fundal negru */
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.selectFieldtrucking:hover,
.selectFieldtrucking:focus {
  background: rgba(0, 0, 0, 1); /* 🔥 Fundal full negru la focus */
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.selectFieldtrucking option {
  background: #000000; /* 🔥 Fundal negru */
  color: white; /* 🔥 Text alb */
  padding: 10px;
}

.selectFieldtrucking option:hover {
  background: rgba(0, 198, 255, 0.2);
  color: #00c6ff;
}
/* 🔹 Butoane pentru generare/anulare link */
.linkControlstrucking {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.linkControlstrucking button {
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: bold;
  background: #00c6ff;
}

.linkControlstrucking button:hover {
  transform: scale(1.05);
}

/* 🔹 Text de încărcare */
.loadingTexttrucking {
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  color: #ff9f00;
}

.linksContainertrucking {
  width: 90%;
  max-width: 800px;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px; /* ✅ Reduce spațiul între elemente */
  margin-bottom: 0; /* ✅ Elimină spațiu extra dedesubt */
  padding-bottom: 0; /* ✅ Elimină spațiu intern */
}

.copyButtontrucking {
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: bold;
}

.copyButtontrucking {
  background: #ff3b3b;
}
/* 🔹 Lista linkuri */
.linksListtrucking {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 250px;
  overflow-y: auto;
}

/* 🔹 Stil pentru fiecare link */
.linkItemtrucking {
  background: rgba(0, 0, 0, 0.8);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.linkItemtrucking p {
  margin: 0;
}

.linkItemtrucking strong {
  color: #00c6ff;
}

/* 🔹 Buton de anulare link */
.cancelButtontrucking {
  padding: 8px 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: bold;
  background: #ff3b3b;
  margin-top: 5px;
}

.cancelButtontrucking:hover {
  transform: scale(1.05);
}

.mapContainertrucking {
  width: 100vw;
  height: calc(100vh - 200px); /* ✅ Se ajustează automat */
  margin-top: -5px; /* ✅ Trage harta mai sus */
  padding-top: 0;
}

/* ✅ Responsive */
@media (max-width: 768px) {
  .selectFieldtrucking,
  .truckingsdriverControls,
  .linksContainertrucking {
    max-width: 100%;
  }

  .mapContainertrucking {
    height: calc(100vh - 50px);
  }

  .linksListtrucking {
    max-height: 200px;
  }
}

.chatContainer {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: #121212;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}

/* 🔹 Sidebar pentru lista șoferilor */
.chatSidebar {
  width: 25%;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-right: 2px solid rgba(255, 255, 255, 0.1);
  overflow-y: auto;
}

/* 🔹 Căutare în lista de șoferi */
.chatsearchInput {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  margin-bottom: 15px;
  transition: all 0.3s ease-in-out;
}

.chatsearchInput:focus {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

/* 🔹 Lista șoferilor */
.chatdriverList {
  flex-grow: 1;
  overflow-y: auto;
}

.chatdriverItem {
  padding: 15px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: bold;
}

.chatdriverItem:hover {
  background: rgba(0, 198, 255, 0.2);
  border-left: 5px solid #00c6ff;
}

.selectedchat {
  background: #00c6ff;
  color: #121212;
  border-left: 5px solid #ffffff;
}

.recordingIndicator {
  display: flex;
  align-items: center;
  background: red;
  color: white;
  padding: 8px;
  border-radius: 8px;
  font-weight: bold;
}

.stopRecordingButton {
  background: white;
  color: red;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-left: 10px;
  transition: background 0.2s ease-in-out;
}

.stopRecordingButton:hover {
  background: #ccc;
}


@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.5); opacity: 0.6; }
  100% { transform: scale(1); opacity: 1; }
}

.audioPreview {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.sendRecordingButton {
  background: #00c6ff;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.2s ease-in-out;
}

.sendRecordingButton:hover {
  background: #0086d4;
}

.cancelRecordingButton {
  background: red;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.2s ease-in-out;
}

.cancelRecordingButton:hover {
  background: darkred;
}

.voiceButton {
  background: #ff4c4c;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background 0.2s ease-in-out;
}

.voiceButton:hover {
  background: #d93c3c;
}

.fileChatButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #00c6ff;
  transition: transform 0.2s ease-in-out;
}

.notificationBadge {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 5px;
  right: 5px;
}
.messageNotification {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: red;
  color: white;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: fadeInOut 5s ease-in-out;
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translateX(-50%) translateY(-20px); }
  10% { opacity: 1; transform: translateX(-50%) translateY(0); }
  90% { opacity: 1; transform: translateX(-50%) translateY(0); }
  100% { opacity: 0; transform: translateX(-50%) translateY(-20px); }
}

.unreadBadge {
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  padding: 5px 8px;
  margin-left: 8px;
  min-width: 20px;
  text-align: center;
}

.fileChatButton:hover {
  transform: scale(1.1);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
}

.modalImage {
  max-width: 100%;
  max-height: 80vh;
  border-radius: 10px;
}

.modalPDF {
  width: 80vw;
  height: 80vh;
  border: none;
}


.selectedFilesPreview {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.previewItem {
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 5px;
  border-radius: 10px;
}

.previewImage {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.previewText {
  color: white;
  font-size: 0.9rem;
  margin-right: 5px;
}

.removeFileButton {
  background: red;
  color: white;
  border: none;
  padding: 2px 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.8rem;
}


.dateHeader {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #ccc;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 8px 12px;
  border-radius: 10px;
  display: inline-block;
  margin: 10px auto;
}

.driverWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  max-width: 60%;
  margin-left: 10px;
}

/* 🔹 Container pentru mesajele dispatcherului */
.dispatcherWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
  max-width: 60%;
  margin-left: auto;
  margin-right: 10px;
}

/* 🔹 Mesajele șoferului */
.driverMessage {
  background-color: #f1f1f1;
  color: black;
  padding: 12px 15px;
  border-radius: 12px;
  border-bottom-left-radius: 0;
  word-wrap: break-word;
}

/* 🔹 Mesajele dispatcherului */
.dispatcherMessage {
  background-color: #007bff;
  color: white;
  padding: 12px 15px;
  border-radius: 12px;
  border-bottom-right-radius: 0;
  word-wrap: break-word;
}

/* 🔹 Ora sub mesajele șoferului */
.driverWrapper .timestamp {
  font-size: 12px;
  color: #888;
  text-align: left;
  margin-top: 3px;
  margin-left: 12px;
}

/* 🔹 Ora sub mesajele dispatcherului */
.dispatcherWrapper .timestamp {
  font-size: 12px;
  color: #ddd;
  text-align: right;
  margin-top: 3px;
  margin-right: 12px;
}


.chatSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.chatMessages {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  height: 100%;
  max-height: 80vh;
}

.messageGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* 🔹 Stilizare mesaje */
.message {
  max-width: 60%;
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 1rem;
  word-wrap: break-word;
}

/* 🔹 Mesaj trimis (Dispecer) */
.outgoingchat {
  background: #00c6ff;
  color: #121212;
  align-self: flex-end;
  border-bottom-right-radius: 0;
}

/* 🔹 Mesaj primit (Șofer) */
.incomingchat {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  align-self: flex-start;
  border-bottom-left-radius: 0;
}

/* 🔹 Input-ul pentru mesaje */
.chatInput {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
}

/* 🔹 Input field */
.inputFieldchat {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.inputFieldchat:focus {
  background: rgba(255, 255, 255, 0.2);
}

/* 🔹 Buton Emoji */
.emojiButtonchat {
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 10px;
  color: #00c6ff;
}

/* 🔹 Buton Send */
.sendButtonchat {
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background: #00c6ff;
  color: #121212;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.sendButtonchat:hover {
  transform: scale(1.05);
}

/* 🔹 Mesaj când nu este selectat niciun șofer */
.noDriverSelectedchat {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
}

.messageChatImageContent {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 10px;
}

.imageChatPreview {
  max-width: 250px; /* Dimensiune fixă pentru uniformitate */
  max-height: 250px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.imageChatPreview:hover {
  transform: scale(1.05);
}

.messageChatPDFContent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
}

.pdfChatViewer {
  width: 250px;
  height: 300px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.messageAudioContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
}

.messageAudioContent audio {
  width: 250px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.1);
}



.scrollButton {
  position: fixed;
  bottom: 100px; /* Se poziționează deasupra butonului de send */
  right: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px;
  width: 50px; /* Dimensiune mai mare */
  height: 50px; /* Dimensiune mai mare */
  border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out, opacity 0.3s;
  opacity: 0.8;
}

.scrollButton:hover {
  transform: scale(1.1);
  opacity: 1;
}
/* 🔽 Responsive */
@media (max-width: 768px) {
  .scrollButton {
    width: 40px;
    height: 40px;
    font-size: 20px;
    bottom: 60px;
    right: 20px;
  }
}


/* ✅ Responsive */
@media (max-width: 768px) {
  .chatContainer {
    flex-direction: column;
  }

  .chatSidebar {
    width: 100%;
    height: 30vh;
    border-right: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  }

  .chatSection {
    height: 70vh;
  }

  .message {
    max-width: 80%;
  }
}

