/* ==========================
   🌟 WOW! PREMIUM ABOUT US PAGE 🌟
   ========================== */

/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

/* General Page Styling */
.aboutUsPageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  overflow: hidden;
  position: relative;
}

/* Video Background */
.aboutUsVideoBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  filter: brightness(0.5) blur(9px);
  display: block;
  opacity: 1;
}

/* Header Menu */
.aboutUsHeaderMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}

/* Logo */
.aboutUsLogo {
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.aboutUsLogo:hover {
  transform: scale(1.1);
}

/* Navigation Menu */
.aboutUsHeaderMenu nav ul {
  display: flex;
  list-style: none;
  gap: 25px;
}

.aboutUsHeaderMenu nav ul li {
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.aboutUsHeaderMenu nav ul li:hover {
  color: #00c6ff;
  transform: scale(1.1);
}

/* Main Content */
.aboutUsMainContent {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 80px 15%;
  text-align: left;
  max-width: 1200px;
  margin: auto;
}

/* Text Section */
.aboutustextSection {
  background: rgba(0, 0, 0, 0.6);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(15px);
  text-align: left;
  animation: fadeIn 0.8s ease-in-out;
  color: #ffffff;
  max-width: 900px;
}

.aboutustextSection h1 {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.aboutustextSection h2 {
  font-size: 2rem;
  margin-top: 25px;
  font-weight: 600;
}

.aboutustextSection p {
  font-size: 1.2rem;
  line-height: 1.8;
  opacity: 0.9;
  margin-bottom: 15px;
}

/* List Styling */
.aboutustextSection ul {
  list-style: none;
  padding-left: 0;
}

.aboutustextSection ul li {
  font-size: 1.2rem;
  padding: 10px 0;
  position: relative;
}

.aboutustextSection ul li::before {
  content: "✔";
  color: #00c6ff;
  font-size: 1.4rem;
  font-weight: bold;
  margin-right: 10px;
}

/* Footer */
.aboutusFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.aboutusFooterlink {
  margin: 0 15px;
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.aboutusFooterlink:hover {
  text-decoration: underline;
  color: #00c6ff;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}
