/* ==========================
   🌟 WOW! PREMIUM LOGIN PAGE 🌟
   ========================== */

/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

/* General Page Styling */
.loginPageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  overflow: hidden;
  position: relative;
}

/* Video Background */
.loginPageVideoBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  filter: brightness(0.5) blur(9px);
  display: block;
  opacity: 1;
}

/* Header Menu */
.loginPageheaderMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}

/* Logo */
.loginPagelogo {
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  background-clip: text; /* Adaugă această linie */
color: transparent;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.loginPagelogo:hover {
  transform: scale(1.1);
}

/* Navigation Menu */
nav ul {
  display: flex;
  list-style: none;
  gap: 25px;
}

nav ul li {
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

nav ul li:hover {
  color: #00c6ff;
  transform: scale(1.1);
}

/* Main Content */
.loginPagemainContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 100px;
  gap: 30px;
  flex-wrap: wrap;
  box-sizing: border-box;
}

/* Left Content */
.leftContentlogin {
  flex: 1;
  max-width: 500px;
  text-align: left;
  padding: 20px;
  animation: fadeIn 1s ease-in-out;
}

.leftContentlogin h1 {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 15px;
  text-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}

.leftContentlogin p {
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 15px;
  opacity: 0.9;
}

/* Login Section - Glassmorphism */
.loginSection {
  flex: 1;
  max-width: 450px;
  background: rgba(255, 255, 255, 0.15);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(15px);
  text-align: center;
  animation: slideUp 0.8s ease-in-out;
}

/* Form Header */
.loginForm h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 700;
}

/* Inputs */
.loginPageinput,
.loginPageinput1,
.loginPageinput2 {
  width: 100%;
  padding: 14px;
  margin: 12px 0;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #ffffff;
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease;
}

.loginPageinput:focus,
.loginPageinput1:focus,
.loginPageinput2:focus {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

/* Login Button */
.loginPagebutton {
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 25px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.loginPagebutton:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: translateY(-5px);
}

/* Links */
.linkslogin {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 30px;
}

.linkslogin button {
  width: 48%;
  padding: 12px;
  border-radius: 25px;
  border: none;
  background: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.linkslogin button:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: scale(1.05);
}

.loginPageFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}


.footerLink {
  margin: 0 15px;
  cursor: pointer;
  font-size: 1rem;
}

.footerLink:hover {
  text-decoration: underline;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slideUp {
  from { opacity: 0; transform: translateY(50px); }
  to { opacity: 1; transform: translateY(0); }
}
/* ==========================
   📱 RESPONSIVE DESIGN 🌍
   ========================== */

/* 🟢 Layout flexibil pe dispozitive mici */
@media (max-width: 1024px) {
  .loginPagemainContent {
    flex-direction: column;
    padding: 50px;
    align-items: center;
    text-align: center;
  }

  .leftContentlogin {
    max-width: 90%;
  }

  .loginSection {
    max-width: 90%;
  }

  .loginPagelogo {
    font-size: 2rem;
  }

  .loginPageheaderMenu {
    padding: 15px 30px;
  }
}

/* 🟡 Ajustare pentru TABLETE (max 768px) */
@media (max-width: 768px) {
  .loginPageContainer {
    padding: 10px;
  }

  .loginPageheaderMenu {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  nav ul {
    flex-direction: column;
    gap: 10px;
  }

  .loginPagemainContent {
    padding: 30px;
  }

  .leftContentlogin h1 {
    font-size: 2.2rem;
  }

  .loginPageinput,
  .loginPageinput1,
  .loginPageinput2,
  .loginPagebutton {
    font-size: 1rem;
    padding: 12px;
  }
}

/* 🔴 OPTIMIZARE pentru TELEFOANE (max 480px) */
@media (max-width: 480px) {
  .loginPagelogo {
    font-size: 1.8rem;
  }

  .loginPageheaderMenu {
    padding: 10px 15px;
  }

  .loginPagemainContent {
    padding: 20px;
  }

  .leftContentlogin {
    max-width: 100%;
  }

  .leftContentlogin h1 {
    font-size: 1.8rem;
  }

  .leftContentlogin p {
    font-size: 1rem;
  }

  .loginSection {
    max-width: 100%;
    padding: 20px;
  }

  .loginPageinput,
  .loginPageinput1,
  .loginPageinput2 {
    padding: 10px;
  }

  .loginPagebutton {
    font-size: 1rem;
    padding: 14px;
  }

  .linkslogin {
    flex-direction: column;
    gap: 10px;
  }

  .linkslogin button {
    width: 100%;
  }

  .loginPageFooter {
    padding: 10px;
    font-size: 0.9rem;
  }
}
