/* ==============================
   🔥 WOW! RESET PASSWORD PAGE 🔥
   ============================== */

/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

/* General Styling */
.resetPasswordPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  background: url('../styles/background.mp4') no-repeat center center/cover;
  position: relative;
  text-align: center;
  backdrop-filter: blur(10px);
  padding: 20px;
}

/* Page Title */
.resetPasswordPage h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
  background: linear-gradient(90deg, #ff416c, #ff4b2b);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 20px;
}

/* Form */
.resetPasswordPage form {
  background: rgba(255, 255, 255, 0.1);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  width: 100%;
  max-width: 400px;
  text-align: left;
}

/* Labels */
.resetPasswordPage label {
  font-size: 1.2rem;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
}

/* Inputs */
.resetPasswordPage input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease;
}

.resetPasswordPage input:focus {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* Success Message */
.resetPasswordsuccess {
  font-size: 1.2rem;
  color: #28a745;
  font-weight: bold;
  margin-top: 20px;
}

/* Error Message */
.resetPassworderrorreset {
  color: #ff4b2b;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Reset Password Button */
.resetPasswordButton {
  width: 100%;
  padding: 14px;
  border-radius: 30px;
  border: none;
  background: linear-gradient(135deg, #ff416c, #ff4b2b);
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  outline: none;
}

.resetPasswordButton:hover {
  background: linear-gradient(135deg, #ff4b2b, #ff416c);
  transform: translateY(-5px);
}

.resetPasswordButton:active {
  transform: translateY(2px);
}
