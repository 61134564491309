/* ==========================
   🚛 SIMPLE STATEMENT PAGE STYLES 🚛
   ========================== */

/* 🔹 Container principal */
.StatementPagecontainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    background: #121212;
  }
  
  /* 🔹 Header */
  .StatementPageheader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
    z-index: 1100;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  /* 🔹 Navigație */
  .StatementPageheader nav {
    display: flex;
    gap: 30px;
  }
  
  .StatementPageheader nav li {
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 500;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .StatementPageheader nav li:hover {
    color: #00c6ff;
    transform: scale(1.1);
  }
  
  /* 🔹 Butoane principale */
  .buttonStatementPage {
    padding: 10px 20px;
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    border: none;
    border-radius: 25px;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  
  .buttonStatementPage:hover {
    background: linear-gradient(135deg, #0072ff, #00c6ff);
    transform: translateY(-3px);
  }
  
  /* 🔹 Logout */
  .buttonStatementPage.logout {
    background: linear-gradient(135deg, #ff4e50, #ff6b6b);
  }
  
  .buttonStatementPage.logout:hover {
    background: linear-gradient(135deg, #ff6b6b, #ff4e50);
  }
  
  /* ==========================
     🚛 STATEMENT SECTION 🚛
     ========================== */
  
  .statementSection {
    margin-top: 80px;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    width: 80%;
    margin: auto;
  }
  
  .statementSection h2 {
    text-align: center;
    font-size: 1.8rem;
    color: #00c6ff;
    margin-bottom: 20px;
  }
  
  .driverStatementPageForm {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .driverStatementPageForm label {
    font-weight: bold;
    color: white;
  }
  
  .driverStatementPageForm input,
  .driverStatementPageForm select {
    padding: 10px;
    border-radius: 8px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
  }
  
  .driverStatementPageForm input:hover,
  .driverStatementPageForm input:focus {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .downloadStatementPageButton {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border-radius: 8px;
    border: none;
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .downloadStatementPageButton:hover {
    background: linear-gradient(135deg, #0072ff, #00c6ff);
    transform: scale(1.05);
  }
  
  /* ==========================
     🚛 SETTINGS SECTION 🚛
     ========================== */
  
  .settingsSimpleStatementPageSection {
    margin-top: 80px;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    width: 80%;
    margin: auto;
  }
  
  .titlesettingsSimpleStatementPage {
    text-align: center;
    font-size: 1.8rem;
    color: #00c6ff;
    margin-bottom: 20px;
  }
  
  .settingsFormsettingsSimpleStatementPage {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .inputFieldsettingsSimpleStatementPage {
    padding: 12px;
    border-radius: 8px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
  }
  
  .inputFieldsettingsSimpleStatementPage:hover,
  .inputFieldsettingsSimpleStatementPage:focus {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .submitButtonClientsettingsSimpleStatementPage {
    padding: 12px;
    font-size: 1rem;
    border-radius: 8px;
    border: none;
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .submitButtonClientsettingsSimpleStatementPage:hover {
    background: linear-gradient(135deg, #0072ff, #00c6ff);
    transform: scale(1.05);
  }
  
  /* 🔹 Subscription Info */
  .subtitlesettingsSimpleStatementPage {
    font-size: 1.5rem;
    font-weight: bold;
    color: #00c6ff;
    margin-top: 20px;
  }
  
  .blackTextsettingsSimpleStatementPage {
    font-size: 1.2rem;
    color: white;
    margin-bottom: 5px;
  }
  
  /* 🔹 Two-Factor Authentication */
  .subtitleTwoFactorsettingsSimpleStatementPage {
    font-size: 1.5rem;
    font-weight: bold;
    color: #00c6ff;
    margin-top: 20px;
  }
  
  .submitButtonTwoFactorsettingsSimpleStatementPage {
    padding: 12px;
    font-size: 1rem;
    border-radius: 8px;
    border: none;
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .submitButtonTwoFactorsettingsSimpleStatementPage:hover {
    background: linear-gradient(135deg, #0072ff, #00c6ff);
    transform: scale(1.05);
  }
  
  /* 🔹 FAQ Button */
  .actionButtonfaqsettingsSimpleStatementPage {
    padding: 12px;
    font-size: 1rem;
    border-radius: 8px;
    border: none;
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .actionButtonfaqsettingsSimpleStatementPage:hover {
    background: linear-gradient(135deg, #0072ff, #00c6ff);
    transform: scale(1.05);
  }
  
  /* ==========================
     🚛 RESPONSIVE DESIGN 🚛
     ========================== */
  
  @media (max-width: 1024px) {
    .StatementPageheader {
      padding: 10px 20px;
    }
  
    .settingsSimpleStatementPageSection,
    .statementSection {
      width: 90%;
    }
  }
  
  @media (max-width: 768px) {
    .StatementPageheader {
      flex-direction: column;
      height: auto;
      padding: 15px;
    }
  
    .StatementPageheader nav {
      margin-top: 10px;
    }
  }
  

  .navStatementSettingsButton {
    background-color: #f1f1f1; /* Fundal neutru */
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out, color 0.3s;
  }
  
  .navStatementSettingsButton:hover {
    background-color: #ddd; /* Schimbare de culoare la hover */
  }
  
  .navStatementButton {
    background-color: #f1f1f1; /* Fundal neutru */
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out, color 0.3s;
  }
  
  .navStatementButton:hover {
    background-color: #ddd; /* Schimbare de culoare la hover */
  }
  
  .activeTab {
    background-color: #007bff; /* Albastru pentru butonul activ */
    color: white;
    font-weight: bold;
  }
  .addDriverButtonStatement {
    background-color: #28a745; /* Verde - indică acțiune pozitivă */
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .addDriverButtonStatement:hover {
    background-color: #218838; /* Verde mai închis la hover */
  }
  
  .addDeductionButtonStatementButton {
    background-color: #dc3545; /* Roșu - indică o acțiune de scădere */
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-top: 10px;
  }
  
  .addDeductionButtonStatement:hover {
    background-color: #c82333; /* Roșu mai închis la hover */
  }
  .addAdditionButtonStatement {
    background-color: #28a745; /* Verde - indică o acțiune de adăugare */
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-top: 10px;
  }
  
  .addAdditionButtonStatement:hover {
    background-color: #218838; /* Verde mai închis la hover */
  }
  .closeButtonStatement {
    background-color: #dc3545; /* Roșu - indică închidere */
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-top: 10px;
  }
  
  .closeButtonStatement:hover {
    background-color: #c82333; /* Roșu mai închis la hover */
  }
  