.loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loadingContainer {
    text-align: center;
  }
  
  .loadingTruck {
    width: 150px;
    animation: drive 2s linear infinite;
  }
  
  .loadingText {
    color: white;
    font-size: 20px;
    margin-top: 20px;
  }
  
  /* Animație pentru camion */
  @keyframes drive {
    0% {
      transform: translateX(-50px);
    }
    50% {
      transform: translateX(50px);
    }
    100% {
      transform: translateX(-50px);
    }
  }
  