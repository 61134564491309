/* ==========================
   🌟 WOW! PREMIUM REGISTER PAGE 🌟
   ========================== */

/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

/* General Page Styling */
.containerRegister {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}

/* Video Background */
.RegisterPageVideoBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  filter: brightness(0.5) blur(9px);
  display: block;
  opacity: 1;
}

/* Logo */
.logoRegister {
  position: absolute;
  top: 20px;
  left: 50px;
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.logoRegister:hover {
  transform: scale(1.1);
}

/* Register Form */
.registerForm {
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(20px);
  text-align: center;
  animation: fadeIn 0.8s ease-in-out;
  max-width: 700px;
  width: 100%;
  margin-top: 80px;
}


/* Form Inputs */
.formRowRegister {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.formRowRegister input {
  flex: 1;
  padding: 14px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: #ffffff;
  font-size: 1rem;
  outline: none;
  transition: background 0.3s ease, border 0.3s ease;
}

.formRowRegister input:focus {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

/* Additional Questions */
.additionalQuestionsRegister {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  margin: 20px 0;
}

.additionalQuestionsRegister label {
  flex: 1;
  color: #ffffff;
  font-size: 1rem;
}

.additionalQuestionsRegister input {
  width: 100%;
  padding: 12px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #ffffff;
  font-size: 1rem;
  outline: none;
  transition: background 0.3s ease, border 0.3s ease;
}

/* Privacy Agreement */
.privacyAgreementRegister {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.privacyAgreementRegister input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.privacyAgreementRegister label {
  font-size: 0.9rem;
  opacity: 0.9;
}

/* Package Display */
.packageRegister {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 0;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 10px;
}

/* Submit Button */
.registerButton {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 25px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.registerButton:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: translateY(-5px);
}

/* Error Message */
.errorMessageRegister {
  color: #ff4d4d;
  font-size: 1rem;
  margin-top: 10px;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}
