/* ==========================
   🌟 WOW! PREMIUM PRICING PAGE 🌟
   ========================== */

/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

/* General Page Styling */
.pricingPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding-top: 80px;
}

/* Video Background */
.pricingPageVideoBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -100; /* 🔥 Asigură-te că video-ul rămâne în fundal */
  filter: brightness(0.5) blur(9px);
  opacity: 1;
}


/* Header */
.pricingPageheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}

/* Logo */
.pricingPagelogo {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.pricingPagelogo:hover {
  transform: scale(1.1);
}

/* Navigation */
.pricingPageheader nav ul {
  display: flex;
  list-style: none;
  gap: 25px;
}

.pricingPageheader nav ul li {
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.pricingPageheader nav ul li:hover {
  color: #00c6ff;
  transform: scale(1.1);
}

/* Title */
.pricingPagetitle {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin-top: 120px;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Pricing Container */
.pricingPageplansContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1400px;
  margin-top: 30px;
}

/* Pricing Card */
.pricingPageplanCard {
  background: rgba(0, 0, 0, 0.6);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(15px);
  text-align: center;
  animation: fadeIn 0.8s ease-in-out;
  color: #ffffff;
  max-width: 300px;
  min-width: 280px;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.pricingPageplanCard:hover {
  transform: scale(1.05);
}

.pricingPageplanCard h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Price */
.pricingPageprice,
.pricingPagepriceAnnual {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0;
}

/* Buttons */
.pricingPageplanCard button {
  width: 100%;
  padding: 12px;
  border-radius: 25px;
  border: none;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.pricingPageplanCard button:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: translateY(-5px);
}

/* Full-Screen Modal */
.pricingPagefullScreenModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  overflow-y: auto; /* 🔥 Permite scroll în modal */
}

/* Modal Content */
.pricingPagemodalContent {
  background: rgba(255, 255, 255, 0.15);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(15px);
  text-align: center;
  animation: fadeIn 0.8s ease-in-out;
  color: #ffffff;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto; /* 🔥 Scroll pentru conținutul modalului */
}

/* Close Button */
.pricingPagecloseButton {
  position: absolute;
  top: 20px;
  right: 30px;
  background: transparent;
  border: none;
  font-size: 2rem;
  color: #ffffff;
  cursor: pointer;
}

.pricingPagecloseButton:hover {
  color: #00c6ff;
}

/* Footer */
.pricingPageFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pricingPagefooterLink {
  margin: 0 15px;
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.pricingPagefooterLink:hover {
  text-decoration: underline;
  color: #00c6ff;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}
