/* ==========================
   ⚙️ PREMIUM NOTIFICATIONS SETTINGS ⚙️
   ========================== */

   .SettingsnotificationContainer {
    position: fixed;
    top: 20px;
    right: 20px;
    max-width: 350px;
    background: rgba(20, 20, 20, 0.95);
    color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(12px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 5000;
    animation: slideInRight 0.6s ease-out forwards, fadeIn 0.5s ease-in-out;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    border-left: 4px solid #00c6ff;
  }

/* Conținutul notificării */
.SettingsnotificationContent {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  padding-right: 15px;
  text-align: left;
}

/* Buton de închidere (X) */
.SettingsnotificationcloseButton {
  position: absolute;
  top: 8px;
  right: 10px;
  background: transparent;
  border: none;
  color: #ff4e50;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease-in-out, transform 0.2s ease;
}

.SettingsnotificationcloseButton:hover {
  color: #ff6b6b;
  transform: scale(1.2);
}

/* Hover pentru efect vizual */
.SettingsnotificationContainer:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.7);
}

/* Efect Glow */
.SettingsnotificationContainer::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 12px;
  background: linear-gradient(135deg, rgba(0, 198, 255, 0.3), rgba(0, 114, 255, 0.3));
  filter: blur(10px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
}

.SettingsnotificationContainer:hover::before {
  opacity: 1;
}

/* Animație Slide-In din Dreapta */
@keyframes slideInRight {
  from {
    right: -400px;
    opacity: 0;
  }
  to {
    right: 20px;
    opacity: 1;
  }
}

/* Efect fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Dispariție treptată */
.SettingsnotificationContainer.hide {
  opacity: 0;
  transform: translateX(30px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
