/* ==========================
   🌟 WOW! PREMIUM FORGET PASSWORD PAGE 🌟
   ========================== */

/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.forgetPasswordPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  overflow: hidden;
  position: relative;
  padding-top: 100px; /* 🔥 Asigură că formularul nu se suprapune cu header-ul */
}

/* Video Background */
.ForgetPasswordPageVideoBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  filter: brightness(0.5) blur(9px);
  display: block;
  opacity: 1;
}

.ForgetPasswordPageheaderMenu {
  display: flex;
  justify-content: space-between; /* 🔥 Asigură că logo-ul e în stânga și meniul în dreapta */
  align-items: center;
  padding: 20px 50px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  position: fixed; /* 🔥 FIXAT SUS */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* 🔥 Mereu deasupra altor elemente */
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}

/* Logo */
.ForgetPasswordPagelogo {
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-left: 0; /* 🛠 Elimină orice centrare */
}


.ForgetPasswordPagelogo:hover {
  transform: scale(1.1);
}
/* Title */
.titleforgetPasswordPage {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Form Styling */
.formforgetPasswordPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(15px);
  text-align: center;
  animation: fadeIn 0.8s ease-in-out;
  color: #ffffff;
  max-width: 500px;
  width: 90%;
}

.formGroupforgetPasswordPage {
  width: 100%;
  margin-bottom: 15px;
  text-align: left;
}

.formGroupforgetPasswordPage label {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 5px;
}

.inputforgetPasswordPage {
  width: 100%;
  padding: 14px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: #ffffff;
  font-size: 1rem;
  outline: none;
  transition: background 0.3s ease, border 0.3s ease;
}

.inputforgetPasswordPage:focus {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

/* Submit Button */
.buttonforgetPasswordPage {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 25px;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.buttonforgetPasswordPage:hover {
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  transform: translateY(-5px);
}

/* Success & Error Messages */
.ForgetPasswordPagesuccessMessage {
  color: #00ff7f;
  font-size: 1rem;
  margin-top: 15px;
  font-weight: bold;
}

.ForgetPasswordPageerrorMessage {
  color: #ff4c4c;
  font-size: 1rem;
  margin-top: 15px;
  font-weight: bold;
}

/* Footer */
.ForgetPasswordPageFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.ForgetPasswordPagefooterLink {
  margin: 0 15px;
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.ForgetPasswordPagefooterLink:hover {
  text-decoration: underline;
  color: #00c6ff;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}
