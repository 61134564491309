/* Container principal */
.notificationContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    backdrop-filter: blur(5px);
    animation: fadeIn 0.3s ease-in-out;
}

/* Notificarea propriu-zisă */
.notification {
    background: rgba(255, 255, 255, 0.15);
    color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(15px);
    text-align: center;
    width: 350px;
    animation: slideIn 0.3s ease-in-out;
}

/* Mesajul */
.notification p {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 15px;
}

/* Butonul */
.confirmButton {
    background: linear-gradient(135deg, #4caf50, #2e7d32);
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
}

.confirmButton:hover {
    background: linear-gradient(135deg, #66bb6a, #388e3c);
    transform: translateY(-3px);
}

/* Animații */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
