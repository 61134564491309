/* ==========================
   🌟 FIXED HELP PAGE 🌟
   ========================== */

/* General Page Styling */
.HelpPageContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    align-items: center;
  }
  
  /* Video Background */
  .HelpPageVideoBackground {
    position: fixed; /* 🔥 FIX: Video-ul rămâne pe fundal */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: brightness(0.5) blur(9px);
    opacity: 1;
  }
  
  /* Header Menu */
  .HelpPageHeaderMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    position: fixed; /* 🔥 FIX: Meniul rămâne sus */
    top: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  }
  
  /* 🔥 Spacer pentru a evita suprapunerea headerului peste conținut */
  .HelpPageSpacer {
    height: 80px;
    width: 100%;
  }
  
  /* Logo */
  .HelpPageLogo {
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
    background: linear-gradient(90deg, #00c6ff, #0072ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .HelpPageLogo:hover {
    transform: scale(1.1);
  }
  
  /* Navigation Menu */
  .HelpPageHeaderMenu nav ul {
    display: flex;
    list-style: none;
    gap: 25px;
  }
  
  .HelpPageHeaderMenu nav ul li {
    cursor: pointer;
    font-size: 1.2rem;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .HelpPageHeaderMenu nav ul li:hover {
    color: #00c6ff;
    transform: scale(1.1);
  }
  
  .HelpPageContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh; /* 🔥 FIX: Se ajustează pe înălțimea paginii */
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto; /* 🔥 Dacă e necesar, se poate face scroll */
  }
  
  
  .HelpPagetextSection {
    background: rgba(0, 0, 0, 0.6);
    padding: 30px; /* 🔥 FIX: Mai puțin padding ca să nu fie prea mare */
    border-radius: 20px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(15px);
    text-align: center;
    color: #ffffff;
    max-width: 500px; /* 🔥 FIX: Se restrânge containerul */
    width: 90%; /* 🔥 Se adaptează pe ecrane mici */
    animation: fadeIn 0.8s ease-in-out;
  }
  
  
  .HelpPagetextSection h1 {
    font-size: 2rem; /* 🔥 FIX: Dimensiunea titlului redusă */
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .HelpPagetextSection p {
    font-size: 1rem; /* 🔥 FIX: Text mai mic */
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  
  .HelpPageForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .HelpPageForm label {
    text-align: left;
    font-size: 1.2rem;
    font-weight: bold;
    color: #ffffff;
    opacity: 0.8;
  }
  
  /* Input Fields */
  .HelpPageForm input,
  .HelpPageForm textarea {
    width: 100%;
    padding: 14px;
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #ffffff;
    font-size: 1rem;
    outline: none;
    transition: background 0.3s ease, border 0.3s ease;
  }
  
  .HelpPageForm textarea {
    height: 120px;
    resize: none;
  }
  
  /* Submit Button */
  .HelpPageForm button {
    width: 100%;
    padding: 14px;
    border: none;
    border-radius: 25px;
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  
  .HelpPageForm button:hover {
    background: linear-gradient(135deg, #0072ff, #00c6ff);
    transform: translateY(-5px);
  }
  
  /* Success & Error Messages */
  .successMessage {
    color: #00ff00;
    font-weight: bold;
  }
  
  .errorMessage {
    color: #ff0000;
    font-weight: bold;
  }
  
  /* Footer */
  .HelpPageFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  
  .HelpPagefooterLink {
    margin: 0 15px;
    cursor: pointer;
    font-size: 1rem;
    transition: color 0.3s ease;
  }
  
  .HelpPagefooterLink:hover {
    text-decoration: underline;
    color: #00c6ff;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  